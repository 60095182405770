import { createStore } from 'effector';
import tezber_regions from '../../data/tezber_regions.json';
import { layerColors } from './utils.js';

const initLayersTree = [
  {
    title: 'ПВЗ Tezber',
    key: 'tezber',
    children: [
      {
        title: 'Существующие',
        key: 'tezber_dots_layer',
        color: '#3bb2d0',
      },
    ],
  },
  {
    title: 'ПВЗ Конкурентов',
    key: 'competitors',
    children: [
      {
        title: 'Jana Post',
        key: 'janaPost',
        color: layerColors.janaPost,
      },
      {
        title: 'Ozon',
        key: 'ozon',
        color: layerColors.ozon,
      },
      {
        title: 'Kimigo',
        key: 'kimigo',
        color: layerColors.kimigo,
      },
      {
        title: '139 Express',
        key: 'express',
        color: layerColors.express,
      },
    ],
  },
  {
    title: 'Другие ПВЗ',
    key: 'other',
    children: [
      {
        title: 'Wildberries',
        key: 'wildberries',
        color: layerColors.wildberries,
      },
      {
        title: 'СДЭК',
        key: 'cdek',
        color: layerColors.cdek,
      },
    ],
  },
  {
    title: 'Население',
    key: 'population',
    children: [
      {
        title: 'Экономически активное',
        key: 'active',
      },
      {
        title: 'Общее',
        key: 'total',
      },
    ],
  },
];

const initSelectedPlaces = [];

tezber_regions.forEach((item) => {
  initSelectedPlaces.push(item.key);
  if (item.children) {
    item.children.forEach((child) => {
      initSelectedPlaces.push(child.key);
    });
  }
});

const initActiveLayers = [];

initLayersTree.forEach((item) => {
  initActiveLayers.push(item.key);
  if (item.children) {
    item.children.forEach((child) => {
      if (child.key !== 'active') {
        initActiveLayers.push(child.key);
      }
    });
  }
});

const initTableLayerOpts = [
  {
    label: 'Существующие ПВЗ',
    value: 'tezber_dots_layer',
    disabled: false,
  },
  {
    label: 'Jana Post',
    value: 'janaPost',
    disabled: false,
  },
  {
    label: 'Ozon',
    value: 'ozon',
    disabled: false,
  },
  {
    label: 'Kimigo',
    value: 'kimigo',
    disabled: false,
  },
  {
    label: '139 Express',
    value: 'express',
    disabled: false,
  },
  {
    label: 'Wildberries',
    value: 'wildberries',
    disabled: false,
  },
  {
    label: 'СДЭК',
    value: 'cdek',
    disabled: false,
  },
];

export const $tezberDots = createStore([]);
$tezberDots.watch((state) => console.log('$tezberDots', state));
export const $tezberStartDots = createStore([]);

export const $tezberListData = createStore(tezber_regions);
$tezberListData.watch((state) => console.log('$tezberListData', state));
export const $checkedPlaces = createStore(initSelectedPlaces);
$checkedPlaces.watch((state) => console.log('$checkedPlaces', state));

export const $tezberTableData = createStore([]);
$tezberTableData.watch((state) => console.log('$tezberTableData', state));

export const $tezberChartData = createStore([]);
$tezberChartData.watch((state) => console.log('$tezberChartData', state));

export const $tezberOurDots = createStore([]);
$tezberOurDots.watch((state) => console.log('$tezberOurDots', state));
export const $tezberOurStartDots = createStore([]);

export const $tableChosenDots = createStore([]);
$tableChosenDots.watch((state) => console.log('$tableChosenDots', state));
export const $tableOurChosenDots = createStore([]);
$tableOurChosenDots.watch((state) => console.log('$tableOurChosenDots', state));

export const $layerTable = createStore('tezber_dots_layer');
export const $layerTableOptions = createStore(initTableLayerOpts);

export const $tezberPopulationHex = createStore([]);
$tezberPopulationHex.watch((state) =>
  console.log('$tezberPopulationHex', state)
);

export const $tezberPopulationStartHex = createStore([]);

export const $tezberLayersModalData = createStore(initLayersTree);
export const $activeLayers = createStore(initActiveLayers);
$activeLayers.watch((state) => console.log('$activeLayers', state));

export const $expressDots = createStore([]);
$expressDots.watch((state) => console.log('$expressDots', state));
export const $expressStartDots = createStore([]);
export const $expressChosenDots = createStore([]);

export const $cdekDots = createStore([]);
$cdekDots.watch((state) => console.log('$cdekDots', state));
export const $cdekStartDots = createStore([]);
export const $cdekChosenDots = createStore([]);

export const $janaPostDots = createStore([]);
$janaPostDots.watch((state) => console.log('$janaPostDots', state));
export const $janaPostStartDots = createStore([]);
export const $janaPostChosenDots = createStore([]);

export const $kimigoDots = createStore([]);
$kimigoDots.watch((state) => console.log('$kimigoDots', state));
export const $kimigoStartDots = createStore([]);
export const $kimigoChosenDots = createStore([]);

export const $ozonDots = createStore([]);
$ozonDots.watch((state) => console.log('$ozonDots', state));
export const $ozonStartDots = createStore([]);
export const $ozonChosenDots = createStore([]);

export const $wildberriesDots = createStore([]);
$wildberriesDots.watch((state) => console.log('$wildberriesDots', state));
export const $wildberriesStartDots = createStore([]);
export const $wildberriesChosenDots = createStore([]);

export const $showPopulationHex = createStore(true);
