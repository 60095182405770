import React from 'react';
import { useUnit } from 'effector-react';
import style from '../RbpLanding.module.css';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import { $language } from '../../../models/rbpLandingModel/index.js';
import useWindowSize from '../../../hooks/useWindowSize.js';
import almaty_business from '../../../assets/RbpLandingImages/Partners/almaty_business.png';
import astanahub from '../../../assets/RbpLandingImages/Partners/astanahub.png';
import atameken from '../../../assets/RbpLandingImages/Partners/atameken.png';
import colliers from '../../../assets/RbpLandingImages/Partners/colliers.png';
import wego from '../../../assets/RbpLandingImages/Partners/wego.png';
import almaty_business_small from '../../../assets/RbpLandingImages/Partners/almaty_business_small.png';
import astanahub_small from '../../../assets/RbpLandingImages/Partners/astanahub_small.png';
import atameken_small from '../../../assets/RbpLandingImages/Partners/atameken_small.png';
import colliers_small from '../../../assets/RbpLandingImages/Partners/colliers_small.png';
import wego_small from '../../../assets/RbpLandingImages/Partners/wego_small.png';

function Partners(props) {
  const language = useUnit($language);
  const [width, height] = useWindowSize();

  return (
    <div className={style.container}>
      <div className={style.partners}>
        {/* <div className={style.section_name}> */}
        {/*  {rbp_landing_translation[language].partners_section} */}
        {/* </div> */}
        <div className={style.section_title}>
          {rbp_landing_translation[language].partners_title}
        </div>
        {width > 1200 ? (
          <div className={style.partners_items_wrapper}>
            <a href="https://colliers.kz/" target="_blank" rel="noreferrer">
              <img src={width > 1200 ? colliers : colliers_small} />
            </a>
            <div className={style.partners_divider} />
            <a href="https://atameken.kz/ru/" target="_blank" rel="noreferrer">
              <img src={width > 1200 ? atameken : atameken_small} />
            </a>
            <div className={style.partners_divider} />
            <a
              href="https://almatybusiness.gov.kz/"
              target="_blank"
              rel="noreferrer"
            >
              <img
                src={width > 1200 ? almaty_business : almaty_business_small}
              />
            </a>
            <div className={style.partners_divider} />
            <a href="https://we-gov.org/" target="_blank" rel="noreferrer">
              <img src={width > 1200 ? wego : wego_small} />
            </a>
            <div className={style.partners_divider} />
            <a href="https://astanahub.com/" target="_blank" rel="noreferrer">
              <img src={width > 1200 ? astanahub : astanahub_small} />
            </a>
          </div>
        ) : (
          <div className={style.partners_items_wrapper}>
            <div className={style.small_partners_wrapper}>
              <a href="https://colliers.kz/" target="_blank" rel="noreferrer">
                <img src={width > 1200 ? colliers : colliers_small} />
              </a>
              <a
                href="https://atameken.kz/ru/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={width > 1200 ? atameken : atameken_small} />
              </a>
            </div>
            <div className={style.small_partners_wrapper}>
              <a
                href="https://almatybusiness.gov.kz/"
                target="_blank"
                rel="noreferrer"
              >
                <img
                  src={width > 1200 ? almaty_business : almaty_business_small}
                />
              </a>
              <a href="https://we-gov.org/" target="_blank" rel="noreferrer">
                <img src={width > 1200 ? wego : wego_small} />
              </a>
            </div>
            <a href="https://astanahub.com/" target="_blank" rel="noreferrer">
              <img src={width > 1200 ? astanahub : astanahub_small} />
            </a>
            <div className={style.small_partners_separator} />
          </div>
        )}
      </div>
    </div>
  );
}

export default Partners;
