export const changeLayerVisibility = (layer, isVisible) => {
  let visibility = isVisible;
  if (typeof isVisible === 'boolean') {
    if (!isVisible) {
      visibility = 'none';
    } else {
      visibility = 'visible';
    }
  }
  window.map.setLayoutProperty(layer, 'visibility', visibility);
};

export const flyTo = (coordinates, zoomLvl) => {
  window.map.flyTo({
    center: coordinates,
    zoom: zoomLvl,
    speed: 1,
  });
};

export const changePointerOnLayer = (layer, isPointer) => {
  if (isPointer) {
    window.map.on('mousemove', layer, (event) => {
      window.map.getCanvas().style.cursor = 'pointer';
    });
    window.map.on('mouseleave', layer, (event) => {
      window.map.getCanvas().style.cursor = '';
    });
  } else {
    window.map.on('mousemove', layer, (event) => {
      window.map.getCanvas().style.cursor = '';
    });
    window.map.on('mouseleave', layer, (event) => {
      window.map.getCanvas().style.cursor = '';
    });
  }
};

export const mapCenterPC = [76.91537388145935, 43.21269175727281];

export const mapCenterTab = [76.92861, 43.20667];
