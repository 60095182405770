import notification from 'antd/es/notification';
import {
  districtPolygonFilter,
  districtsFilter,
  gradientFilter,
  polygonFilter,
} from './filters.js';

export default function filterHexagons(
  hexagons,
  activeFilters,
  isochroneStore,
  rbpLayer
) {
  const filteredHexagons = hexagons.filter((item) => {
    const polygons = [...activeFilters.draw_polygon, ...isochroneStore];
    if (!gradientFilter(activeFilters, item, rbpLayer)) return false;
    if (activeFilters.district.length > 0 && polygons.length > 0) {
      if (!districtPolygonFilter(activeFilters.district, polygons, item))
        return false;
    } else if (activeFilters.district.length > 0 || polygons.length > 0) {
      if (!districtsFilter(activeFilters, item)) return false;
      if (!polygonFilter(polygons, item)) return false;
    }
    return true;
  });
  notification.config({
    maxCount: 1,
    duration: 3,
  });
  if (filteredHexagons.length === 0) {
    notification[window.location.pathname === '/tezber' ? 'info' : 'error']({
      message: window.location.pathname === '/tezber' ? 'Info' : 'Error',
      description: 'При данной фильтрации результат 0 гексагонов',
      placement: 'topRight',
      className: 'notification',
    });

    return [];
  }

  return filteredHexagons;
}
