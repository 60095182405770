import React from 'react';
import Promo from '../sections/Promo.jsx';
import Features from '../sections/Features.jsx';
import HowItWorks from '../sections/HowItWorks.jsx';
import OpenMap from '../sections/OpenMap.jsx';
import Prices from '../sections/Prices.jsx';
import Partners from '../sections/Partners.jsx';
import FAQ from '../sections/FAQ.jsx';
import Contacts from '../sections/Contacts.jsx';

function MainLanding() {
  return (
    <>
      <Promo />
      <Features />
      <Prices />
      <HowItWorks />
      <OpenMap />
      <Partners />
      <FAQ />
      <Contacts />
    </>
  );
}

export default MainLanding;
