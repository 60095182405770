import React from 'react';
import { useUnit } from 'effector-react';
import {
  $janaPostChosenDots,
  $janaPostDots,
} from '../../../models/tezberModel/index.js';
import TezberDotsPreset from './TezberDotsPreset.jsx';
import { layerColors } from '../../../models/tezberModel/utils.js';

function JanaPostLayer() {
  const dots = useUnit($janaPostDots);
  const tableChosenDots = useUnit($janaPostChosenDots);

  return (
    <TezberDotsPreset
      dots={dots}
      tableChosenDots={tableChosenDots}
      layerName="janaPost"
      color={layerColors.janaPost}
    />
  );
}

export default JanaPostLayer;
