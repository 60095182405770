import React from 'react';
import { useUnit } from 'effector-react';
import { CheckOutlined } from '@ant-design/icons';
import { Button, Image } from 'antd';
import style from '../RbpLanding.module.css';
import report_placeholder_big from '../../../assets/RbpLandingImages/Payments/report_placeholder_big.png';
import report_placeholder_small from '../../../assets/RbpLandingImages/Payments/report_placeholder_small.png';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import { $language } from '../../../models/rbpLandingModel/index.js';
import payment_report_img_1 from '../../../assets/RbpLandingImages/Payments/payment_report_img_1.png';
import payment_report_img_2 from '../../../assets/RbpLandingImages/Payments/payment_report_img_2.png';
import payment_report_img_3 from '../../../assets/RbpLandingImages/Payments/payment_report_img_3.png';
import payment_report_img_4 from '../../../assets/RbpLandingImages/Payments/payment_report_img_4.png';
import useWindowSize from '../../../hooks/useWindowSize.js';

function PaymentReports() {
  const language = useUnit($language);
  const [width, height] = useWindowSize();

  const params = [
    rbp_landing_translation[language].payments_reports_params_1,
    rbp_landing_translation[language].payments_reports_params_2,
    rbp_landing_translation[language].payments_reports_params_3,
    rbp_landing_translation[language].payments_reports_params_4,
    rbp_landing_translation[language].payments_reports_params_5,
    rbp_landing_translation[language].payments_reports_params_6,
    rbp_landing_translation[language].payments_reports_params_7,
    rbp_landing_translation[language].payments_reports_params_8,
  ];

  return (
    <div className={style.container}>
      <div className={style.payments_reports}>
        {width > 1200 ? (
          <div className={style.payments_reports_images}>
            <Image src={payment_report_img_1} height={154} width="49%" />
            <Image src={payment_report_img_2} height={154} width="49%" />
            <Image src={payment_report_img_3} height={154} width="49%" />
            <Image src={payment_report_img_4} height={154} width="49%" />
          </div>
        ) : (
          <div>
            <Image src={payment_report_img_1} height="100%" width="100%" />
            <div style={{ overflowX: 'scroll' }}>
              <div
                className={style.payments_reports_images_mobile}
                style={{
                  width: '135%',
                }}
              >
                <Image src={payment_report_img_2} height={85} width="45%" />
                <Image src={payment_report_img_3} height={85} width="45%" />
                <Image src={payment_report_img_4} height={85} width="45%" />
              </div>
            </div>
          </div>
        )}
        <div>
          {/* <div className={style.section_name}> */}
          {/*  {rbp_landing_translation[language].payments_reports_section} */}
          {/* </div> */}
          <div
            className={[style.section_title, style.section_title_small].join(
              ' '
            )}
          >
            {rbp_landing_translation[language].payments_reports_title}
          </div>
          <div className={style.payments_reports_desc}>
            {rbp_landing_translation[language].payments_reports_desc_1}
          </div>
          <div
            className={style.payments_reports_desc}
            style={{ marginTop: 15 }}
          >
            {rbp_landing_translation[language].payments_reports_desc_2}
          </div>
          <div className={style.payments_reports_params}>
            {params.map((item, index) => {
              return (
                <div
                  className={style.payments_reports_param}
                  key={`param_${index}`}
                >
                  <CheckOutlined className={style.payments_reports_check} />
                  {item}
                </div>
              );
            })}
          </div>
          <Button className={style.payments_reports_download}>
            {rbp_landing_translation[language].payments_reports_download}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default PaymentReports;
