import { Switch } from 'antd';
import { useUnit } from 'effector-react';
import SelectPreset from '../SelectPreset/SelectPreset.jsx';
import { $language } from '../../models/authModel/index.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { districts_dictionary } from '../../dictionaries/districts_dictionary.js';
import { $userAppointedPreset } from '../../models/dataPresetsModel/index.js';

const options = [
  {
    value: 1,
    label: 'Бостандыкский район',
  },
  {
    value: 2,
    label: 'Ауэзовский район',
  },
  {
    value: 3,
    label: 'Алатауский район',
  },
  {
    value: 4,
    label: 'Медеуский район',
  },
  {
    value: 5,
    label: 'Наурызбайский район',
  },
  {
    value: 6,
    label: 'Жетысуский район',
  },
  {
    value: 7,
    label: 'Алмалинский район',
  },
  {
    value: 8,
    label: 'Турксибский район',
  },
];
function DistrictsSelector() {
  const language = useUnit($language);
  const userAppointedPreset = useUnit($userAppointedPreset);
  const switchOption = ({ isSelected }) => {
    return <Switch checked={isSelected} />;
  };

  return (
    <SelectPreset
      options={Array.from({ length: 8 }).map((item, index) => {
        return {
          value: index + 1,
          label: districts_dictionary[index + 1][language],
          disabled: !!userAppointedPreset,
        };
      })}
      field="district"
      menuItemSelectedIcon={switchOption}
      mode="multiple"
      placeholder={ru_en_page_dictionary.districts_placeholder[language]}
      debounce
      style={{ width: '100%' }}
    />
  );
}

export default DistrictsSelector;
