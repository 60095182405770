import { createEffect } from 'effector';
import { wsGetIsochrone } from '../../utils/webSocketConfig.js';
import { api } from '../../api/axiosinstance.js';

export const getIsochroneFx = createEffect().use(
  ({ coordinates, time, traffic, type }) => {
    coordinates.forEach((point) => {
      wsGetIsochrone({
        coordinates: point.geometry.coordinates,
        time: traffic ? time / 2.5 : time,
        traffic,
        type,
      });
    });
    // wsGetIsochrone({ coordinates, time, traffic, type });
  }
);

export const getPublicIsochroneFx = createEffect().use(async (payload) => {
  return (
    await api().get(
      `map/get-isochrone?lat=${payload.lat}&lon=${payload.lon}&time=${payload.time}`
    )
  ).data;
});
