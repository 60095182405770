import { sample } from 'effector';
import { combineEvents, condition, spread } from 'patronum';
import { getTezberDataFx } from './effects.js';
import {
  $layerTable,
  $checkedPlaces,
  $tableChosenDots,
  $tableOurChosenDots,
  $tezberChartData,
  $tezberDots,
  $tezberPopulationHex,
  $tezberPopulationStartHex,
  $tezberStartDots,
  $tezberTableData,
  $activeLayers,
  $expressDots,
  $expressStartDots,
  $cdekDots,
  $cdekStartDots,
  $janaPostDots,
  $janaPostStartDots,
  $kimigoDots,
  $kimigoStartDots,
  $ozonDots,
  $ozonStartDots,
  $wildberriesDots,
  $wildberriesStartDots,
  $layerTableOptions,
  $expressChosenDots,
  $cdekChosenDots,
  $janaPostChosenDots,
  $kimigoChosenDots,
  $ozonChosenDots,
  $wildberriesChosenDots,
  $showPopulationHex,
} from './stores.js';
import {
  calcChartData,
  filterDots,
  formTableData,
  mutateHexagons,
  staticGradient,
} from './utils.js';
import {
  changeLayerTableEv,
  changeCheckedPlacesEv,
  changeTableChosenEv,
  changeTableOurChosenEv,
  clearPlacesEv,
  invertPlacesEv,
  selectAllPlacesEv,
  updateTableAndChartEv,
  changeActiveLayersEv,
  changeExpressChosenDotsEv,
  changeCdekChosenDotsEv,
  changeJanaPostChosenDotsEv,
  changeKimigoChosenDotsEv,
  changeOzonChosenDotsEv,
  changeWildberriesChosenDotsEv,
  resetByGradientEv,
  setByPLaceEv,
  setWithGradientEv,
  toggleShowPopulationHexEv,
} from './events.js';
import {
  calculateThresholdsEv,
  putThresholdsEv,
} from '../gradientModel/index.js';
import tezber_regions from '../../data/tezber_regions.json';
import filterHexagons from '../../counters/hex_counter/hex_counter.js';
import {
  $activeFilters,
  changeActiveFilterEv,
  clearActiveGradientEv,
} from '../activeFiltersModel/index.js';

const allPlaces = [];

tezber_regions.forEach((item) => {
  allPlaces.push(item.key);
  if (item.children) {
    item.children.forEach((child) => {
      allPlaces.push(child.key);
    });
  }
});

spread({
  source: getTezberDataFx.doneData,
  targets: {
    express: [$expressDots, $expressStartDots],
    cdek: [$cdekDots, $cdekStartDots],
    janaPost: [$janaPostDots, $janaPostStartDots],
    kimigo: [$kimigoDots, $kimigoStartDots],
    ozon: [$ozonDots, $ozonStartDots],
    wildberries: [$wildberriesDots, $wildberriesStartDots],
  },
});

$tezberDots.on(getTezberDataFx.doneData, (_, payload) => payload.dots);
$tezberStartDots.on(getTezberDataFx.doneData, (_, payload) => payload.dots);

// $tezberListData.on(getTezberDataFx.doneData, (_, payload) =>
//   payload.places.map((item) => item.ZHK_name)
// );

$tezberTableData.on(getTezberDataFx.doneData, (_, payload) =>
  formTableData(payload.dots)
);

$tezberChartData
  .on(getTezberDataFx.doneData, (_, payload) =>
    calcChartData({
      tezber_dots_layer: payload.tezber_dots_layer,
      express: payload.express,
      cdek: payload.cdek,
      janaPost: payload.janaPost,
      kimigo: payload.kimigo,
      ozon: payload.ozon,
      wildberries: payload.wildberries,
    })
  )
  .on(updateTableAndChartEv, (_, payload) =>
    calcChartData({
      tezber_dots_layer: payload.tezber_dots_layer,
      express: payload.express,
      cdek: payload.cdek,
      janaPost: payload.janaPost,
      kimigo: payload.kimigo,
      ozon: payload.ozon,
      wildberries: payload.wildberries,
    })
  );

// $tezberOurDots.on(getTezberDataFx.doneData, (_, payload) => payload.ourDots);
// $tezberOurStartDots.on(
//   getTezberDataFx.doneData,
//   (_, payload) => payload.ourDots
// );

$checkedPlaces.on(changeCheckedPlacesEv, (_, payload) => payload);

$layerTable.on(changeLayerTableEv, (_, payload) => payload);

$showPopulationHex.on(toggleShowPopulationHexEv, (state, payload) => !state);

sample({
  clock: changeTableChosenEv,
  target: $tableChosenDots,
});
sample({
  clock: changeTableOurChosenEv,
  target: $tableOurChosenDots,
});

sample({
  source: [
    $tezberStartDots,
    $expressStartDots,
    $cdekStartDots,
    $janaPostStartDots,
    $kimigoStartDots,
    $ozonStartDots,
    $wildberriesStartDots,
  ],
  clock: $checkedPlaces.updates,
  fn: ([tezber, express, cdek, janaPost, kimigo, ozon, wildberries], clock) => {
    if (clock.length > 0) {
      return {
        tezber: filterDots(tezber, clock),
        express: filterDots(express, clock),
        cdek: filterDots(cdek, clock),
        janaPost: filterDots(janaPost, clock),
        kimigo: filterDots(kimigo, clock),
        ozon: filterDots(ozon, clock),
        wildberries: filterDots(wildberries, clock),
      };
    }
    return {
      tezber: [],
      express: [],
      cdek: [],
      janaPost: [],
      kimigo: [],
      ozon: [],
      wildberries: [],
    };
  },
  target: spread({
    targets: {
      tezber: $tezberDots,
      express: $expressDots,
      cdek: $cdekDots,
      janaPost: $janaPostDots,
      kimigo: $kimigoDots,
      ozon: $ozonDots,
      wildberries: $wildberriesDots,
    },
  }),
});

// sample({
//   source: $tezberOurStartDots,
//   clock: $checkedPlaces.updates,
//   fn: (source, clock) => {
//     if (clock.length > 0) {
//       return source.filter((item) => {
//         return clock.includes(
//           item.properties.region || item.properties.settlement
//         );
//       });
//     }
//     return source;
//   },
//   target: $tezberOurDots,
// });

sample({
  source: [$tezberPopulationStartHex, $checkedPlaces],
  clock: [$checkedPlaces.updates, $activeFilters.updates],
  filter: () => window.location.pathname === '/tezber',
  fn: ([startHex, checkedPlaces], clock) => {
    const updated = Object.hasOwn(clock, 'gradient') ? 'gradient' : 'place';
    if (checkedPlaces.length > 0) {
      return {
        hex: startHex.filter((item) => {
          return (
            checkedPlaces.includes(item.properties.region) ||
            checkedPlaces.includes(item.properties.settlement)
          );
        }),
        updated,
      };
    }
    return {
      hex: [],
      updated,
    };
  },
  target: condition({
    if: (payload) => payload.updated === 'place',
    then: [setByPLaceEv, clearActiveGradientEv],
    else: setWithGradientEv,
  }),
});

sample({
  clock: setByPLaceEv,
  fn: (clock) => clock.hex,
  target: $tezberPopulationHex,
});

sample({
  source: $activeFilters,
  clock: setWithGradientEv,
  fn: (source, clock) => {
    return filterHexagons(clock.hex, source, [], 'index');
  },
  target: $tezberPopulationHex,
});

combineEvents({
  events: {
    tezber_dots_layer: $tezberDots.updates,
    express: $expressDots.updates,
    cdek: $cdekDots.updates,
    janaPost: $janaPostDots.updates,
    kimigo: $kimigoDots.updates,
    ozon: $ozonDots.updates,
    wildberries: $wildberriesDots.updates,
  },
  target: updateTableAndChartEv,
});

sample({
  clock: $checkedPlaces.updates,
  fn: () => [],
  target: [$tableChosenDots, $tableOurChosenDots],
});

sample({
  clock: selectAllPlacesEv,
  fn: () => allPlaces,
  target: $checkedPlaces,
});

sample({
  clock: clearPlacesEv,
  fn: () => [],
  target: $checkedPlaces,
});

sample({
  source: $checkedPlaces,
  clock: invertPlacesEv,
  fn: (source, clock) => {
    return allPlaces.filter((item) => !source.includes(item));
  },
  target: $checkedPlaces,
});

sample({
  clock: getTezberDataFx.doneData,
  fn: (clock) => {
    return {
      hexagons: clock.population.map((item) => {
        return {
          ...item,
          properties: {
            ...item.properties,
            index_main: item.properties.pop_total,
          },
        };
      }),
      gradient: staticGradient.total,
    };
  },
  target: spread({
    targets: {
      hexagons: [$tezberPopulationHex, $tezberPopulationStartHex],
      gradient: putThresholdsEv,
    },
  }),
});

sample({
  clock: changeActiveLayersEv,
  fn: (clock) => clock.nodes,
  target: $activeLayers,
});

sample({
  clock: changeActiveLayersEv,
  target: clearActiveGradientEv,
});

sample({
  source: [
    $tezberPopulationStartHex,
    $tezberPopulationHex,
    $checkedPlaces,
    $activeFilters,
  ],
  clock: changeActiveLayersEv,
  filter: (_, clock) =>
    clock.changedNode === 'active' || clock.changedNode === 'total',
  fn: ([startHex, hex, checkedPlaces, activeFilters], clock) => {
    let hexagons = hex;

    if (activeFilters.gradient.length > 0) {
      hexagons = startHex.filter((item) => {
        return (
          checkedPlaces.includes(item.properties.region) ||
          checkedPlaces.includes(item.properties.settlement)
        );
      });
    }

    return {
      startHex: mutateHexagons(startHex, clock.changedNode),
      hex: mutateHexagons(hexagons, clock.changedNode),
      gradient: staticGradient[clock.changedNode],
    };
  },
  target: spread({
    targets: {
      startHex: $tezberPopulationStartHex,
      hex: [$tezberPopulationHex],
      gradient: putThresholdsEv,
    },
  }),
});

sample({
  source: $layerTableOptions,
  clock: $activeLayers.updates,
  fn: (source, clock) => {
    return source.map((item) => {
      return {
        ...item,
        disabled: !clock.includes(item.value),
      };
    });
  },
  target: $layerTableOptions,
});

sample({
  source: [
    $tezberDots,
    $expressDots,
    $cdekDots,
    $janaPostDots,
    $kimigoDots,
    $ozonDots,
    $wildberriesDots,
  ],
  clock: $layerTable.updates,
  fn: ([tezber, express, cdek, janaPost, kimigo, ozon, wildberries], clock) => {
    switch (clock) {
      case 'tezber_dots_layer':
        return formTableData(tezber);
      case 'express':
        return formTableData(express);
      case 'cdek':
        return formTableData(cdek);
      case 'janaPost':
        return formTableData(janaPost);
      case 'kimigo':
        return formTableData(kimigo);
      case 'ozon':
        return formTableData(ozon);
      case 'wildberries':
        return formTableData(wildberries);
    }
  },
  target: $tezberTableData,
});

sample({
  clock: changeExpressChosenDotsEv,
  target: $expressChosenDots,
});
sample({
  clock: changeCdekChosenDotsEv,
  target: $cdekChosenDots,
});
sample({
  clock: changeJanaPostChosenDotsEv,
  target: $janaPostChosenDots,
});
sample({
  clock: changeKimigoChosenDotsEv,
  target: $kimigoChosenDots,
});
sample({
  clock: changeOzonChosenDotsEv,
  target: $ozonChosenDots,
});
sample({
  clock: changeWildberriesChosenDotsEv,
  target: $wildberriesChosenDots,
});

sample({
  source: $layerTable,
  clock: $activeLayers.updates,
  filter: (source, clock) => !clock.includes(source),
  fn: (source, clock) => {
    return (
      clock.find((item) => {
        return (
          item !== 'tezber' &&
          item !== 'competitors' &&
          item !== 'other' &&
          item !== 'active' &&
          item !== 'total' &&
          item !== 'population'
        );
      }) || null
    );
  },
  target: $layerTable,
});

sample({
  source: $layerTable,
  clock: updateTableAndChartEv,
  fn: (
    layerTable,
    { tezber_dots_layer, express, cdek, janaPost, kimigo, ozon, wildberries }
  ) => {
    switch (layerTable) {
      case 'tezber_dots_layer':
        return formTableData(tezber_dots_layer);
      case 'express':
        return formTableData(express);
      case 'cdek':
        return formTableData(cdek);
      case 'janaPost':
        return formTableData(janaPost);
      case 'kimigo':
        return formTableData(kimigo);
      case 'ozon':
        return formTableData(ozon);
      case 'wildberries':
        return formTableData(wildberries);
    }
  },
  target: $tezberTableData,
});
