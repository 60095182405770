import React, { useCallback, useMemo, useState } from 'react';
import {
  Button,
  Checkbox,
  Input,
  Popover,
  Radio,
  Segmented,
  Space,
  Table,
} from 'antd';
import { useUnit } from 'effector-react';
import { MenuOutlined } from '@ant-design/icons';
import {
  $cdekChosenDots,
  $expressChosenDots,
  $janaPostChosenDots,
  $kimigoChosenDots,
  $layerTable,
  $ozonChosenDots,
  $tableChosenDots,
  $tableOurChosenDots,
  $tezberTableData,
  $wildberriesChosenDots,
  changeCdekChosenDotsEv,
  changeExpressChosenDotsEv,
  changeJanaPostChosenDotsEv,
  changeKimigoChosenDotsEv,
  changeLayerTableEv,
  changeOzonChosenDotsEv,
  changeTableChosenEv,
  changeTableOurChosenEv,
  changeWildberriesChosenDotsEv,
} from '../../../models/tezberModel/index.js';
import { flyTo } from '../../../utils/mapbox-utils.js';
import { ru_en_page_dictionary } from '../../../dictionaries/ru_en_page_dictionary.js';
import { $language } from '../../../models/authModel/index.js';
import LayersMenu from './LayersMenu.jsx';
import { formColumns } from '../../../models/tezberModel/utils.js';

function PlacesTable() {
  const data = useUnit($tezberTableData);
  const [type, setType] = useState('their');
  const layerTable = useUnit($layerTable);
  const tableChosenDots = useUnit($tableChosenDots);
  const tableOurChosenDots = useUnit($tableOurChosenDots);
  const expressChosenDots = useUnit($expressChosenDots);
  const cdekChosenDots = useUnit($cdekChosenDots);
  const janaPostChosenDots = useUnit($janaPostChosenDots);
  const kimigoChosenDots = useUnit($kimigoChosenDots);
  const ozonChosenDots = useUnit($ozonChosenDots);
  const wildberriesChosenDots = useUnit($wildberriesChosenDots);
  const changeTableChosen = useUnit(changeTableChosenEv);
  const changeTableOurChosen = useUnit(changeTableOurChosenEv);
  const changeExpressChosenDots = useUnit(changeExpressChosenDotsEv);
  const changeCdekChosenDots = useUnit(changeCdekChosenDotsEv);
  const changeJanaPostChosenDots = useUnit(changeJanaPostChosenDotsEv);
  const changeKimigoChosenDots = useUnit(changeKimigoChosenDotsEv);
  const changeOzonChosenDots = useUnit(changeOzonChosenDotsEv);
  const changeWildberriesChosenDots = useUnit(changeWildberriesChosenDotsEv);

  const language = useUnit($language);

  const addSortAndFilter = useCallback(
    (field) => {
      return {
        filterSearch: true,
        filters: Array.from(new Set(data.map((item) => item[field]))).map(
          (item) => {
            return {
              text: item,
              value: item,
            };
          }
        ),
        onFilter: (value, record) => {
          if (typeof value === 'object') {
            switch (value.type) {
              case '>':
                return record[field] > value.value;
              case '<':
                return record[field] < value.value;
              case '=':
                return record[field] === value.value;
            }
          } else {
            return record[field] === value;
          }
        },
        sorter: (a, b) => {
          if (typeof a[field] === 'string') {
            return a[field].localeCompare(b[field]);
          }
          return a[field] - b[field];
        },
        filterDropdown:
          field === 'pop_18_60' ||
          field === 'pop_total' ||
          field === 'pvz_area' ||
          field === 'GRAV_pop_18_60' ||
          field === 'GRAV_pop_total'
            ? ({
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                close,
              }) => (
                <div
                  style={{
                    padding: 5,
                  }}
                >
                  <Input
                    value={[selectedKeys[0]?.value]}
                    onChange={(e) =>
                      setSelectedKeys(
                        e.target.value
                          ? [
                              {
                                value: e.target.value,
                                type: selectedKeys[0]?.type,
                              },
                            ]
                          : []
                      )
                    }
                    style={{
                      marginBottom: 5,
                    }}
                  />
                  <Radio.Group
                    value={selectedKeys[0]?.type || null}
                    onChange={(e) => {
                      setSelectedKeys(
                        e.target.value
                          ? [
                              {
                                value: selectedKeys[0]?.value,
                                type: e.target.value,
                              },
                            ]
                          : []
                      );
                    }}
                  >
                    <Radio value=">">{'>'}</Radio>
                    <Radio value="<">{'<'}</Radio>
                    <Radio value="=">=</Radio>
                  </Radio.Group>
                  <div
                    style={{
                      display: 'flex',
                      columnGap: 10,
                      justifyContent: 'flex-end',
                    }}
                  >
                    <Button
                      onClick={() => {
                        if (selectedKeys[0].value && selectedKeys[0].type) {
                          confirm();
                        }
                        console.log('selectedKeys', selectedKeys);
                      }}
                      type="primary"
                    >
                      OK
                    </Button>
                    <Button
                      onClick={() => {
                        clearFilters();
                        setSelectedKeys([]);
                        confirm();
                      }}
                    >
                      Reset
                    </Button>
                  </div>
                </div>
              )
            : null,
      };
    },
    [data]
  );

  const columns = useMemo(() => {
    return formColumns(data[0], addSortAndFilter);
  }, [layerTable, data]);

  let chosenState;
  let changeTableState;

  switch (layerTable) {
    case 'tezber_dots_layer':
      {
        chosenState = tableChosenDots;
        changeTableState = changeTableChosen;
      }
      break;
    case 'express':
      {
        chosenState = expressChosenDots;
        changeTableState = changeExpressChosenDots;
      }
      break;
    case 'cdek':
      {
        chosenState = cdekChosenDots;
        changeTableState = changeCdekChosenDots;
      }
      break;
    case 'janaPost':
      {
        chosenState = janaPostChosenDots;
        changeTableState = changeJanaPostChosenDots;
      }
      break;
    case 'kimigo':
      {
        chosenState = kimigoChosenDots;
        changeTableState = changeKimigoChosenDots;
      }
      break;
    case 'ozon':
      {
        chosenState = ozonChosenDots;
        changeTableState = changeOzonChosenDots;
      }
      break;
    case 'wildberries':
      {
        chosenState = wildberriesChosenDots;
        changeTableState = changeWildberriesChosenDots;
      }
      break;
  }

  return (
    <div style={{ overflow: 'scroll', height: '100%' }}>
      <LayersMenu />
      <Table
        dataSource={data}
        columns={columns}
        locale={{
          triggerDesc: ru_en_page_dictionary.triggerDesc[language],
          triggerAsc: ru_en_page_dictionary.triggerAsc[language],
          cancelSort: ru_en_page_dictionary.cancelSort[language],
        }}
        pagination={false}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: chosenState,
          onChange: (selectedRowKeys, selectedRows) => {
            changeTableState(selectedRowKeys);
            if (selectedRows.length > 0) {
              flyTo(
                selectedRows[selectedRows.length - 1].coordinates,
                window.map.getZoom() >= 13 ? 13 : window.map.getZoom() + 1
              );
            } else {
              const center = window.map.getCenter();
              flyTo([center.lng, center.lat], 9);
            }
          },
        }}
      />
    </div>
  );
}

export default PlacesTable;
