import Icon from '@ant-design/icons';
import style from './CursorIcon.module.css';

function ClickEffectSvg() {
  return (
    <svg
      width={12}
      height={13}
      viewBox="0 0 12 13"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.59619 11.7857L4.59619 11.3857L4.59619 11.7857C5.23132 11.7857 5.74619 11.2708 5.74619 10.6357C5.74619 10.0006 5.23132 9.4857 4.59619 9.4857L1.76777 9.4857C1.13264 9.4857 0.617767 10.0006 0.617767 10.6357C0.617767 11.2708 1.13264 11.7857 1.76777 11.7857L4.59619 11.7857ZM9.10305 4.97884L9.50305 4.97884L9.10305 4.97884C9.10305 5.61397 9.61792 6.12884 10.253 6.12884C10.8882 6.12884 11.403 5.61397 11.403 4.97884L11.403 2.15041C11.403 1.51529 10.8882 1.00041 10.253 1.00042C9.61792 1.00041 9.10305 1.51529 9.10305 2.15041L9.10305 4.97884ZM6.11647 4.87278C5.66737 4.42367 4.93923 4.42367 4.49013 4.87278C4.04103 5.32188 4.04102 6.05002 4.49013 6.49912L6.61145 8.62044C7.06055 9.06954 7.78869 9.06954 8.23779 8.62044C8.6869 8.17134 8.6869 7.4432 8.23779 6.9941L6.11647 4.87278Z"
        fill="#2D9CDB"
        stroke="white"
        strokeWidth={0.8}
        strokeLinecap="round"
      />
    </svg>
  );
}

function CursorSvg() {
  return (
    <svg
      width={22}
      height={23}
      viewBox="0 0 22 23"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.718 20.3826L11.7951 15.4597L10.2918 16.963C8.75272 18.502 7.9832 19.2716 7.15612 19.0899C6.32903 18.9082 5.95281 17.8871 5.20037 15.8447L2.69088 9.03327C1.18984 4.95901 0.439319 2.92189 1.48926 1.87195C2.5392 0.822009 4.57633 1.57253 8.65059 3.07357L15.4621 5.58306C17.5044 6.3355 18.5256 6.71172 18.7072 7.53881C18.8889 8.36589 18.1194 9.13541 16.5803 10.6745L15.077 12.1778L19.9999 17.1006C20.5096 17.6104 20.7645 17.8652 20.8822 18.1496C21.0393 18.5286 21.0393 18.9546 20.8822 19.3337C20.7645 19.618 20.5096 19.8728 19.9999 20.3826C19.4901 20.8923 19.2353 21.1472 18.951 21.2649C18.5719 21.4219 18.1459 21.4219 17.7669 21.2649C17.4825 21.1472 17.2277 20.8923 16.718 20.3826Z"
        fill="#2D9CDB"
        stroke="white"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}

export default function CursorIcon() {
  return (
    <div className={style.cursor_icon}>
      <Icon component={ClickEffectSvg} className={style.click_effect} />
      <Icon component={CursorSvg} />
    </div>
  );
}
