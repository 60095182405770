import React, { useState } from 'react';
import { Button, Drawer, Tree } from 'antd';
import {
  EyeInvisibleOutlined,
  EyeOutlined,
  LeftOutlined,
} from '@ant-design/icons';
import { useUnit } from 'effector-react';
import {
  $activeLayers,
  $showPopulationHex,
  $tezberLayersModalData,
  changeActiveLayersEv,
  toggleShowPopulationHexEv,
} from '../../../models/tezberModel/index.js';
import style from './LayersModal.module.css';

const formatData = (
  data,
  activeLayers,
  showPopulationHex,
  toggleShowPopulationHex
) => {
  return data.map((item) => {
    return {
      ...item,
      title: (
        <div className={style.layer_node}>
          {item.color ? (
            <div className={style.node_with_color}>
              <div
                className={style.layer_color}
                style={{ background: item.color }}
              />
              {item.title}
            </div>
          ) : (
            item.title
          )}
          {item.key === 'population' ? (
            showPopulationHex ? (
              <EyeOutlined onClick={toggleShowPopulationHex} />
            ) : (
              <EyeInvisibleOutlined onClick={toggleShowPopulationHex} />
            )
          ) : activeLayers.includes(item.key) ? (
            <EyeOutlined />
          ) : (
            <EyeInvisibleOutlined />
          )}
        </div>
      ),
      checkable: item.key !== 'population',
      children: item.children ? formatData(item.children, activeLayers) : [],
    };
  });
};

function LayersModal() {
  const [open, setOpen] = useState(true);
  const data = useUnit($tezberLayersModalData);
  const activeLayers = useUnit($activeLayers);
  const changeActiveLayers = useUnit(changeActiveLayersEv);
  const showPopulationHex = useUnit($showPopulationHex);
  const toggleShowPopulationHex = useUnit(toggleShowPopulationHexEv);

  const onCheck = (nodes, e) => {
    const { node } = e;
    let temp = {
      nodes: [...nodes],
      changedNode: null,
    };
    if (node.key === 'active' || node.key === 'total') {
      temp = {
        nodes: temp.nodes.filter(
          (item) => item !== (node.key === 'active' ? 'total' : 'active')
        ),
        changedNode: node.key,
      };
    }
    changeActiveLayers(temp);
  };

  return (
    <>
      <Button
        icon={<LeftOutlined />}
        className={style.layer_modal_button}
        onClick={() => setOpen(true)}
      />
      <Drawer
        open={open}
        getContainer={false}
        placement="right"
        mask={false}
        width={300}
        onClose={() => setOpen(false)}
      >
        <Tree
          id="layers_tree"
          treeData={formatData(
            data,
            activeLayers,
            showPopulationHex,
            toggleShowPopulationHex
          )}
          checkable
          showIcon
          multiple
          selectable={false}
          onCheck={onCheck}
          checkedKeys={activeLayers}
          defaultExpandedKeys={['tezber']}
        />
        {/* <MapStyleSelector /> */}
      </Drawer>
    </>
  );
}

export default LayersModal;
