import React, { useState, useEffect } from 'react';
//FIXME RCP URL FOR AI ASSISTANT
// const apiUrl = 'https://asst.metatable.ai/create_message'
const apiUrl = 'https://elnet.metatable.ai/create_message';
const authToken =
  '2b993bb5aa86d16f300224326e7f864804ddb99b2dd91f017367bc5c9aae5621';

function ChatV2() {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [threadId, setThreadId] = useState(null);
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    // Добавляем стартовое сообщение при загрузке виджета
    const initialMessage = {
      text: `Добрый день!<br/>
      Я – цифровой помощник, который поможет вам получить технические условия для подключения к электроснабжению. Подскажу, как собрать документы, подать заявку и пройти все этапы подключения.`,
      fromUser: false,
    };
    setMessages([initialMessage]);
  }, []);
  function processText(input) {
    // Разбиваем текст на строки
    const lines = input.split(/(?<=\\.)\\d+\\.\\s/); // Разделяем по номеру с точкой и пробелом
    const result = [];

    lines.forEach(line => {
      // Убираем `**` и заменяем содержимое внутри `【】` на пустую строку
      const cleanedLine = line
        .replace(/\\*\\*/g, '')
        .replace(/【[^】]*】/g, '')
        .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')
        .replace(/(\s\d+\.)/g, '<br/>$1')
        .replace(/(\s-\s)/g, '<br/>$1');


      if (cleanedLine.trim()) {
        result.push(cleanedLine.trim());
      }
    });

    // Возвращаем текст с новой строки для каждого пункта
    return result.join('\\n');
  }

  const sendMessage = async () => {
    if (!input.trim()) return;

    // Отображаем исходящее сообщение
    const newMessages = [...messages, { text: input, fromUser: true }];
    setMessages(newMessages);
    setInput('');
    setIsTyping(true);

    // Отправляем сообщение в API
    try {
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${authToken}`,
        },
        body: JSON.stringify({
          message: input,
          thread_id: threadId,
        }),
      });
      const data = await response.json();

      // Сохраняем thread_id при первом ответе
      if (!threadId) {
        setThreadId(data.thread_id);
      }

      // Анимация печати входящего сообщения
      animateIncomingMessage(data.message, newMessages);
    } catch (error) {
      console.error('Ошибка при запросе API:', error);
    } finally {
      setIsTyping(false);
    }
  };

  // Функция для анимации печати текста побуквенно
  const animateIncomingMessage = (text, newMessages) => {
    let index = 0;
    const intervalId = setInterval(() => {
      setMessages([
        ...newMessages,
        { text: processText(text.slice(0, index + 1)), fromUser: false },
      ]);
      index++;
      if (index === text.length) {
        clearInterval(intervalId);
      }
    }, 5); // Скорость печати в миллисекундах
  };

  return (
    <div style={styles.chatContainer}>
      <div style={styles.messagesContainer}>
        {messages.map((msg, index) => (
          <div
            key={index}
            style={{
              ...styles.message,
              ...(msg.fromUser ? styles.userMessage : styles.apiMessage),
            }}
          >
            <p
              style={styles.messageText}
              dangerouslySetInnerHTML={{ __html: msg.text }} // Используем dangerouslySetInnerHTML для отображения HTML
            />
          </div>
        ))}
        {isTyping && (
          <div style={styles.typingIndicator}>
            <p>Typing...</p>
          </div>
        )}
      </div>
      <div style={styles.inputContainer}>
        <input
          type="text"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          placeholder="Задайте вопрос Виртуальному консультанту"
          style={styles.input}
          onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
        />
        <button onClick={sendMessage} style={styles.sendButton}>
          Отправить
        </button>
      </div>
    </div>
  );
}

const styles = {
  chatContainer: {
    width: '100%',
    maxWidth: '850px',
    border: '1px solid #ccc',
    borderRadius: '8px',
    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
    overflow: 'hidden',
    fontFamily: 'Arial, sans-serif',
  },
  messagesContainer: {
    maxHeight: '400px',
    overflowY: 'auto',
    padding: '10px',
    backgroundColor: '#f9f9f9',
  },
  message: {
    marginBottom: '10px',
    padding: '8px 12px',
    borderRadius: '18px',
    maxWidth: '80%',
  },
  userMessage: {
    backgroundColor: '#DCF8C6',
    alignSelf: 'flex-end',
    textAlign: 'right',
    marginLeft: 'auto',
    color: '#333',
  },
  apiMessage: {
    backgroundColor: '#FFF',
    border: '1px solid #e6e6e6',
    color: '#333',
    textAlign: 'left',
  },
  typingIndicator: {
    marginBottom: '10px',
    padding: '8px 12px',
    borderRadius: '18px',
    maxWidth: '80%',
    backgroundColor: '#e6e6e6',
    color: '#666',
    fontStyle: 'italic',
  },
  messageText: {
    margin: 0,
    fontSize: '14px',
    lineHeight: '1.4',
  },
  inputContainer: {
    display: 'flex',
    alignItems: 'center',
    padding: '10px',
    borderTop: '1px solid #ddd',
    backgroundColor: '#fff',
  },
  input: {
    flex: 1,
    padding: '10px',
    borderRadius: '18px',
    border: '1px solid #ddd',
    fontSize: '14px',
    outline: 'none',
    marginRight: '8px',
  },
  sendButton: {
    padding: '8px 12px',
    border: 'none',
    borderRadius: '18px',
    backgroundColor: '#72bf44',
    color: '#fff',
    fontWeight: 'bold',
    cursor: 'pointer',
    transition: 'background-color 0.2s',
  },
  sendButtonHover: {
    backgroundColor: '#0056b3',
  },
};

export default ChatV2;
