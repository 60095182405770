import React from 'react';
import { Button, Checkbox, Popover, Radio, Select, Space } from 'antd';
import { MenuOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import {
  $layerTable,
  $layerTableOptions,
  changeLayerTableEv,
} from '../../../models/tezberModel/index.js';

function LayersMenu() {
  const layerTable = useUnit($layerTable);
  const changeLayerTable = useUnit(changeLayerTableEv);
  const options = useUnit($layerTableOptions);

  const onChange = (e) => {
    changeLayerTable(e.target.value);
  };

  const popoverContent = () => {
    return (
      <div>
        <Radio.Group onChange={onChange} value={layerTable}>
          <Space direction="vertical">
            {options.map((item) => {
              return (
                <Radio key={`option-${item.label}`} value={item.value}>
                  {item.label}
                </Radio>
              );
            })}
          </Space>
        </Radio.Group>
      </div>
    );
  };

  return (
    // <Popover content={popoverContent} trigger="click">
    //   <Button icon={<MenuOutlined />} />
    // </Popover>
    <Select
      options={options}
      value={layerTable}
      onChange={changeLayerTable}
      style={{
        width: 300,
      }}
    />
  );
}

export default LayersMenu;
