import React from 'react';
import { useUnit } from 'effector-react';
import {
  $ozonChosenDots,
  $ozonDots,
} from '../../../models/tezberModel/index.js';
import TezberDotsPreset from './TezberDotsPreset.jsx';
import { layerColors } from '../../../models/tezberModel/utils.js';

function OzonLayer() {
  const dots = useUnit($ozonDots);
  const tableChosenDots = useUnit($ozonChosenDots);

  return (
    <TezberDotsPreset
      dots={dots}
      tableChosenDots={tableChosenDots}
      layerName="ozon"
      color={layerColors.ozon}
    />
  );
}

export default OzonLayer;
