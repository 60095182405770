import React from 'react';
import { useUnit } from 'effector-react';
import { Button } from 'antd';
import style from '../RbpLanding.module.css';
import openmap_bg from '../../../assets/RbpLandingImages/OpenMap/openmap_bg.png';
import { $language } from '../../../models/rbpLandingModel/index.js';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';

function OpenMap(props) {
  const language = useUnit($language);

  const onClick = () => {
    window.open(
      'https://gis.almatybusiness.gov.kz/ru/businesses/place-select/',
      '_blank'
    );
  };

  return (
    <div className={style.container}>
      <div className={style.openmap} id={'openmap'}>
        <div className={style.openmap_overlay} />
        <div className={style.openmap_title}>
          {rbp_landing_translation[language].openmap_title}
        </div>
        <div className={style.openmap_desc}>
          {rbp_landing_translation[language].openmap_desc}
        </div>
        <Button className={style.openmap_button} onClick={onClick}>
          {rbp_landing_translation[language].openmap_button}
        </Button>
      </div>
    </div>
  );
}

export default OpenMap;
