import React from 'react';
import { useUnit } from 'effector-react';
import {
  $kimigoChosenDots,
  $kimigoDots,
} from '../../../models/tezberModel/index.js';
import TezberDotsPreset from './TezberDotsPreset.jsx';
import { layerColors } from '../../../models/tezberModel/utils.js';

function KimigoLayer() {
  const dots = useUnit($kimigoDots);
  const tableChosenDots = useUnit($kimigoChosenDots);

  return (
    <TezberDotsPreset
      dots={dots}
      tableChosenDots={tableChosenDots}
      layerName="kimigo"
      color={layerColors.kimigo}
    />
  );
}

export default KimigoLayer;
