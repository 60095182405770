import React from 'react';
import { useUnit } from 'effector-react';
import { CheckOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';
import { $language } from '../../../models/rbpLandingModel/index.js';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import style from '../RbpLanding.module.css';
import audience from '../../../assets/RbpLandingImages/Prices/audience.svg';
import business from '../../../assets/RbpLandingImages/Prices/business.svg';
import traffic from '../../../assets/RbpLandingImages/Prices/traffic.svg';
import useWindowSize from '../../../hooks/useWindowSize.js';
import rbp_report_example from '../../../data/rbp_report_example.pdf';

function Prices() {
  const language = useUnit($language);
  const navigate = useNavigate();
  const [width, height] = useWindowSize();

  const openPayment = () => {
    navigate('/payment');
    window.scrollTo(0, 0);
  };

  const openPublic = () => {
    window.open(
      'https://gis.almatybusiness.gov.kz/ru/businesses/place-select/',
      '_blank'
    );
  };

  const scrollToContacts = () => {
    document.getElementById('contacts').scrollIntoView();
  };

  const downloadPdf = () => {
    const link = document.createElement('a');
    link.href = rbp_report_example;
    link.download = 'RBP Lite report example.pdf';
    link.click();
  };

  const products = [
    {
      title: 'RBP Free',
      cost: rbp_landing_translation[language].prices_free,
      audience_features: [
        rbp_landing_translation[language].prices_audience_feature_1,
        rbp_landing_translation[language].prices_audience_feature_2,
      ],
      business_features: [
        rbp_landing_translation[language].prices_business_feature_1,
        rbp_landing_translation[language].prices_business_feature_2,
      ],
      traffic_features: [
        rbp_landing_translation[language].prices_traffic_feature_1,
      ],
      button: rbp_landing_translation[language].prices_goto,
      onClick: openPublic,
    },
    {
      title: 'RBP Lite',
      cost: ['от 9 000₸', rbp_landing_translation[language].prices_report],
      audience_features: [
        rbp_landing_translation[language].prices_audience_feature_3,
        rbp_landing_translation[language].prices_audience_feature_4,
      ],
      business_features: [
        rbp_landing_translation[language].prices_business_feature_3,
        rbp_landing_translation[language].prices_business_feature_4,
        rbp_landing_translation[language].prices_business_feature_5,
      ],
      traffic_features: [
        rbp_landing_translation[language].prices_traffic_feature_2,
      ],
      button: rbp_landing_translation[language].prices_try,
      onClick: openPayment,
    },
    {
      title: 'RBP PRO',
      cost: rbp_landing_translation[language].prices_on_request,
      audience_features: [
        rbp_landing_translation[language].prices_audience_feature_5,
        rbp_landing_translation[language].prices_audience_feature_6,
      ],
      business_features: [
        rbp_landing_translation[language].prices_business_feature_6,
        rbp_landing_translation[language].prices_business_feature_7,
      ],
      traffic_features: [
        rbp_landing_translation[language].prices_traffic_feature_3,
        rbp_landing_translation[language].prices_traffic_feature_4,
      ],
      button: rbp_landing_translation[language].prices_request,
      onClick: scrollToContacts,
    },
  ];

  return (
    <div className={style.container}>
      <div className={style.prices} id="prices">
        {/* <div className={style.section_name}> */}
        {/*  {rbp_landing_translation[language].prices_section} */}
        {/* </div> */}
        <div className={style.section_title}>
          {rbp_landing_translation[language].prices_title}
        </div>
        <div className={style.section_subtitle}>
          {rbp_landing_translation[language].prices_subtitle}
        </div>
        <div className={style.prices_cards_wrapper}>
          {products.map((item, index) => {
            return (
              <div className={style.prices_card} key={`prices_card_${index}`}>
                <div className={style.prices_card_title}>
                  {item.title}
                  {item.tag && (
                    <div className={style.prices_card_tag}>{item.tag}</div>
                  )}
                </div>
                {/* <div className={style.prices_card_desc}>{item.desc}</div> */}
                <div className={style.prices_card_cost}>
                  {Array.isArray(item.cost) ? (
                    <>
                      <div style={{ color: '#1780BC', display: 'inline' }}>
                        {item.cost[0]}
                      </div>
                      <div className={style.prices_card_cost_slash}>
                        {item.cost[1]}
                      </div>
                    </>
                  ) : (
                    item.cost
                  )}
                </div>
                <div className={style.prices_card_features_wrapper}>
                  <div className={style.prices_feature_block}>
                    <div className={style.prices_card_feature_title}>
                      <img src={audience} />
                      {rbp_landing_translation[language].prices_audience}
                    </div>
                    <div className={style.prices_card_features}>
                      {item.audience_features.map((feature, feature_index) => {
                        return (
                          <div
                            className={style.prices_card_feature}
                            key={`prices_card_${index}_feature_${feature_index}`}
                          >
                            <CheckOutlined
                              style={{
                                marginRight: 8,
                                color: '#2D9CDB',
                              }}
                            />
                            {feature}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={style.prices_card_features_divider} />
                  <div
                    style={{
                      height: width > 1200 ? 220 : 'unset',
                    }}
                    className={style.prices_feature_block}
                  >
                    <div className={style.prices_card_feature_title}>
                      <img src={business} />
                      {rbp_landing_translation[language].prices_business}
                    </div>
                    <div className={style.prices_card_features}>
                      {item.business_features.map((feature, feature_index) => {
                        return (
                          <div
                            className={style.prices_card_feature}
                            key={`prices_card_${index}_feature_${feature_index}`}
                          >
                            <CheckOutlined
                              style={{
                                marginRight: 8,
                                color: '#2D9CDB',
                              }}
                            />
                            {feature}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                  <div className={style.prices_card_features_divider} />
                  <div
                    style={{ minHeight: index === 1 ? 74 : 138 }}
                    className={style.prices_feature_block}
                  >
                    <div className={style.prices_card_feature_title}>
                      <img src={traffic} />
                      {rbp_landing_translation[language].prices_traffic}
                    </div>
                    <div className={style.prices_card_features}>
                      {item.traffic_features.map((feature, feature_index) => {
                        return (
                          <div
                            className={style.prices_card_feature}
                            key={`prices_card_${index}_feature_${feature_index}`}
                          >
                            <CheckOutlined
                              style={{
                                marginRight: 8,
                                color: '#2D9CDB',
                              }}
                            />
                            {feature}
                          </div>
                        );
                      })}
                    </div>
                  </div>
                </div>
                {index === 1 ? (
                  <div>
                    <Button
                      className={style.prices_download_button}
                      onClick={downloadPdf}
                    >
                      {rbp_landing_translation[language].prices_download}
                    </Button>
                    <Button
                      className={style.prices_card_button}
                      onClick={item.onClick}
                    >
                      {item.button}
                    </Button>
                  </div>
                ) : (
                  <Button
                    className={style.prices_card_button}
                    onClick={item.onClick}
                  >
                    {item.button}
                  </Button>
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default Prices;
