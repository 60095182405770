import React from 'react';
import { useUnit } from 'effector-react';
import style from '../RbpLanding.module.css';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import { $language } from '../../../models/rbpLandingModel/index.js';
import feature_1 from '../../../assets/RbpLandingImages/Features/feature_1.svg';
import feature_2 from '../../../assets/RbpLandingImages/Features/feature_2.svg';
import feature_3 from '../../../assets/RbpLandingImages/Features/feature_3.svg';
import feature_4 from '../../../assets/RbpLandingImages/Features/feature_4.svg';
import useWindowSize from '../../../hooks/useWindowSize.js';

function Features() {
  const language = useUnit($language);
  const [width, height] = useWindowSize();

  const features = [
    {
      title: rbp_landing_translation[language].features_card_1_title,
      desc: rbp_landing_translation[language].features_card_1_desc,
      img: feature_1,
    },
    {
      title: rbp_landing_translation[language].features_card_2_title,
      desc: rbp_landing_translation[language].features_card_2_desc,
      img: feature_2,
    },
    {
      title: rbp_landing_translation[language].features_card_3_title,
      desc: rbp_landing_translation[language].features_card_3_desc,
      img: feature_3,
    },
    {
      title: rbp_landing_translation[language].features_card_4_title,
      desc: rbp_landing_translation[language].features_card_4_desc,
      img: feature_4,
    },
  ];

  return (
    <div className={style.container}>
      <div className={style.features} id={'features'}>
        {/* <div className={style.section_name}> */}
        {/*  {rbp_landing_translation[language].features_section} */}
        {/* </div> */}
        <div className={style.section_title}>
          {rbp_landing_translation[language].features_title}
        </div>
        <div className={style.section_subtitle}>
          {rbp_landing_translation[language].features_subtitle}
        </div>
        {width > 1200 ? (
          <div className={style.features_cards_wrapper}>
            {features.map((item, index) => {
              return (
                <div key={`feature-${index}`} className={style.features_card}>
                  <img src={item.img} />
                  <div className={style.features_card_title}>{item.title}</div>
                  <div className={style.features_card_desc}>{item.desc}</div>
                </div>
              );
            })}
          </div>
        ) : (
          <div style={{ width: '100%', overflowX: 'scroll' }}>
            <div
              style={{
                width: (window.innerWidth - 138) * 4 + 60,
              }}
              className={style.features_cards_wrapper}
            >
              {features.map((item, index) => {
                return (
                  <div key={`feature-${index}`} className={style.features_card}>
                    <img src={item.img} />
                    <div className={style.features_card_title}>
                      {item.title}
                    </div>
                    <div className={style.features_card_desc}>{item.desc}</div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Features;
