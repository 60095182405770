import React, { useEffect } from 'react';
import mapboxgl from 'mapbox-gl';
import { useUnit } from 'effector-react';
import { $mapLoaded } from '../../../models/mapModel/index.js';
import { $activeLayers } from '../../../models/tezberModel/index.js';
import { changeLayerVisibility } from '../../../utils/mapbox-utils.js';

function TezberDotsPreset(props) {
  const { dots, tableChosenDots, layerName, color } = props;
  const mapLoaded = useUnit($mapLoaded);
  const activeLayers = useUnit($activeLayers);

  const createLayer = (update) => {
    const dotsData = {
      type: 'FeatureCollection',
      features:
        tableChosenDots.length > 0
          ? dots.filter((item) => tableChosenDots.includes(item.properties.id))
          : dots,
    };

    if (update) {
      window.map.getSource(`tezber_${layerName}_source`).setData(dotsData);
    } else {
      if (!window.map.getSource(`tezber_${layerName}_source`)) {
        window.map.addSource(`tezber_${layerName}_source`, {
          type: 'geojson',
          data: dotsData,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });
      }

      if (!window.map.getLayer(`tezber_${layerName}_layer`)) {
        // FIXME Cluster layer
        window.map.addLayer({
          id: `tezber_${layerName}_layer`,
          type: 'circle',
          source: `tezber_${layerName}_source`,
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': color,
            'circle-radius': 10,
          },
        });

        // FIXME Cluster count layer
        window.map.addLayer({
          id: `tezber_${layerName}_count_layer`,
          type: 'symbol',
          source: `tezber_${layerName}_source`,
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-size': 12,
          },
        });

        // FIXME Unclustered dots layer
        window.map.addLayer({
          id: `tezber_${layerName}_unclustered_layer`,
          type: 'circle',
          source: `tezber_${layerName}_source`,
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': color,
            'circle-radius': 10,
          },
        });

        // FIXME Zoom in on cluster click + cursor on hover
        window.map.on('click', `tezber_${layerName}_layer`, (e) => {
          const features = window.map.queryRenderedFeatures(e.point, {
            layers: [`tezber_${layerName}_layer`],
          });
          const clusterId = features[0].properties.cluster_id;
          window.map
            .getSource(`tezber_${layerName}_source`)
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              window.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom,
              });
            });
        });

        window.map.on('mouseenter', `tezber_${layerName}_layer`, () => {
          window.map.getCanvas().style.cursor = 'pointer';
        });

        window.map.on('mouseleave', `tezber_${layerName}_layer`, () => {
          window.map.getCanvas().style.cursor = '';
        });

        // FIXME Popup on dot click + cursor on hover
        window.map.on('click', `tezber_${layerName}_unclustered_layer`, (e) => {
          const feature = e.features[0];
          const coordinates = feature.geometry.coordinates.slice();
          const html = `
            <div>
                <div  style="color: black;">Существующие ПВЗ</div>
                <div style="color: black;">Название ПВЗ: ${
                  feature.properties.name
                }</div>
                <div style="color: black;">Охват ЦА: ${
                  feature.properties.pop_18_60
                } чел.</div>
                <div style="color: black;">Охват населения: ${
                  feature.properties.pop_total
                } чел.</div>
                <div style="color: black;">Грав. модель ЦА: ${
                  feature.properties.GRAV_pop_18_60
                } чел.</div>
                <div style="color: black;">Грав. модель общий: ${
                  feature.properties.GRAV_pop_total
                } чел.</div>
                <div style="color: black;">Регион: ${
                  feature.properties.region
                }</div> 
                <div style="color: black;">Населенный пункт: ${
                  feature.properties.settlement || '-'
                }</div>
            </div>`;

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(window.map);
        });

        window.map.on(
          'mouseenter',
          `tezber_${layerName}_unclustered_layer`,
          () => {
            window.map.getCanvas().style.cursor = 'pointer';
          }
        );

        window.map.on(
          'mouseleave',
          `tezber_${layerName}_unclustered_layer`,
          () => {
            window.map.getCanvas().style.cursor = '';
          }
        );

        setTimeout(() => {
          window.map.moveLayer(`tezber_${layerName}_unclustered_layer`);
          window.map.moveLayer(`tezber_${layerName}_layer`);
          window.map.moveLayer(`tezber_${layerName}_count_layer`);
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (mapLoaded) {
      if (window.map.getLayer(`tezber_${layerName}_layer`)) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [mapLoaded, dots, tableChosenDots]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer(`tezber_${layerName}_layer`)) {
      if (!activeLayers.includes(layerName)) {
        changeLayerVisibility(`tezber_${layerName}_layer`, false);
        changeLayerVisibility(`tezber_${layerName}_count_layer`, false);
        changeLayerVisibility(`tezber_${layerName}_unclustered_layer`, false);
      } else {
        changeLayerVisibility(`tezber_${layerName}_layer`, true);
        changeLayerVisibility(`tezber_${layerName}_count_layer`, true);
        changeLayerVisibility(`tezber_${layerName}_unclustered_layer`, true);
      }
    }
  }, [mapLoaded, activeLayers]);

  return null;
}

export default TezberDotsPreset;
