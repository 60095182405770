import React, { useEffect } from 'react';
import { useUnit } from 'effector-react';
import mapboxgl from 'mapbox-gl';
import {
  $activeLayers,
  $tableChosenDots,
  $tableOurChosenDots,
  $tezberDots,
} from '../../../models/tezberModel/index.js';
import { $mapLoaded } from '../../../models/mapModel/index.js';
import { changeLayerVisibility } from '../../../utils/mapbox-utils.js';

function TezberLayer() {
  const tezberDots = useUnit($tezberDots);
  const mapLoaded = useUnit($mapLoaded);
  const tableChosenDots = useUnit($tableChosenDots);
  const activeLayers = useUnit($activeLayers);

  const createLayer = (update) => {
    const tezberDotsData = {
      type: 'FeatureCollection',
      features:
        tableChosenDots.length > 0
          ? tezberDots.filter((item) =>
              tableChosenDots.includes(item.properties.name)
            )
          : tezberDots,
    };
    if (update) {
      window.map.getSource('tezber_dots_source').setData(tezberDotsData);
    } else {
      if (!window.map.getSource('tezber_dots_source')) {
        window.map.addSource('tezber_dots_source', {
          type: 'geojson',
          data: tezberDotsData,
          cluster: true,
          clusterMaxZoom: 14,
          clusterRadius: 50,
        });
      }
      if (!window.map.getLayer('tezber_dots_layer')) {
        // window.map.addLayer({
        //   id: 'tezber_dots_layer',
        //   type: 'circle',
        //   source: 'tezber_dots_source',
        //   paint: {
        //     'circle-radius': {
        //       base: 1.75,
        //       stops: [
        //         [12, 2],
        //         [22, 180],
        //       ],
        //     },
        //     'circle-color': '#3bb2d0',
        //   },
        // });

        // FIXME Cluster layer
        window.map.addLayer({
          id: 'tezber_dots_layer',
          type: 'circle',
          source: 'tezber_dots_source',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': '#3bb2d0',
            'circle-radius': 10,
          },
        });

        // FIXME Cluster count layer
        window.map.addLayer({
          id: 'tezber_dots_count_layer',
          type: 'symbol',
          source: 'tezber_dots_source',
          filter: ['has', 'point_count'],
          layout: {
            'text-field': ['get', 'point_count_abbreviated'],
            'text-size': 12,
          },
        });

        // FIXME Unclustered dots layer
        window.map.addLayer({
          id: 'tezber_dots_unclustered_layer',
          type: 'circle',
          source: 'tezber_dots_source',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#3bb2d0',
            'circle-radius': 10,
          },
        });

        // FIXME Zoom in on cluster click + cursor on hover
        window.map.on('click', 'tezber_dots_layer', (e) => {
          const features = window.map.queryRenderedFeatures(e.point, {
            layers: ['tezber_dots_layer'],
          });
          const clusterId = features[0].properties.cluster_id;
          window.map
            .getSource('tezber_dots_source')
            .getClusterExpansionZoom(clusterId, (err, zoom) => {
              if (err) return;

              window.map.easeTo({
                center: features[0].geometry.coordinates,
                zoom,
              });
            });
        });

        window.map.on('mouseenter', 'tezber_dots_layer', () => {
          window.map.getCanvas().style.cursor = 'pointer';
        });

        window.map.on('mouseleave', 'tezber_dots_layer', () => {
          window.map.getCanvas().style.cursor = '';
        });

        // FIXME Popup on dot click + cursor on hover
        window.map.on('click', 'tezber_dots_unclustered_layer', (e) => {
          const feature = e.features[0];
          const coordinates = feature.geometry.coordinates.slice();
          const html = `
            <div>
                <div  style="color: black;">Существующие ПВЗ</div>
                <div style="color: black;">Название ПВЗ: ${
                  feature.properties.name
                }</div>
                <div style="color: black;">Охват ЦА: ${
                  feature.properties.pop_18_60
                } чел.</div>
                <div style="color: black;">Охват населения: ${
                  feature.properties.pop_total
                } чел.</div>
                <div style="color: black;">Грав. модель ЦА: ${
                  feature.properties.GRAV_pop_18_60
                } чел.</div>
                <div style="color: black;">Грав. модель общий: ${
                  feature.properties.GRAV_pop_total
                } чел.</div>
                <div style="color: black;">Регион: ${
                  feature.properties.region
                }</div> 
                <div style="color: black;">Населенный пункт: ${
                  feature.properties.settlement || '-'
                }</div>
            </div>`;

          new mapboxgl.Popup()
            .setLngLat(coordinates)
            .setHTML(html)
            .addTo(window.map);
        });

        window.map.on('mouseenter', 'tezber_dots_unclustered_layer', () => {
          window.map.getCanvas().style.cursor = 'pointer';
        });

        window.map.on('mouseleave', 'tezber_dots_unclustered_layer', () => {
          window.map.getCanvas().style.cursor = '';
        });

        setTimeout(() => {
          window.map.moveLayer(`tezber_dots_unclustered_layer`);
          window.map.moveLayer(`tezber_dots_layer`);
          window.map.moveLayer(`tezber_dots_count_layer`);
        }, 500);
      }
    }
  };

  useEffect(() => {
    if (mapLoaded) {
      if (window.map.getLayer('tezber_dots_layer')) {
        createLayer(true);
      } else {
        setTimeout(() => {
          createLayer(false);
        }, 500);
      }
    }
  }, [tezberDots, mapLoaded, tableChosenDots]);

  useEffect(() => {
    if (mapLoaded && window.map.getLayer('tezber_dots_layer')) {
      if (!activeLayers.includes('tezber_dots_layer')) {
        changeLayerVisibility('tezber_dots_layer', false);
        changeLayerVisibility('tezber_dots_count_layer', false);
        changeLayerVisibility('tezber_dots_unclustered_layer', false);
      } else {
        changeLayerVisibility('tezber_dots_layer', true);
        changeLayerVisibility('tezber_dots_count_layer', true);
        changeLayerVisibility('tezber_dots_unclustered_layer', true);
      }
    }
  }, [mapLoaded, activeLayers]);

  return null;
}

export default TezberLayer;
