import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { Divider, Tooltip } from 'antd';
import {
  DoubleLeftOutlined,
  DoubleRightOutlined,
  InfoCircleOutlined,
  StarOutlined,
} from '@ant-design/icons';
import style from './LiteCategory.module.css';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../../../models/activeFiltersModel/index.js';
import {
  CompetitorsIcon,
  HouseholdsIcon,
  IncomeIcon,
  PopulationIcon,
  PotentialIcon,
  RentIcon,
  SpendingsIcon,
  TrafficIcon,
  WorkingIcon,
} from './icons.jsx';
import LiteCategoryDetails from '../LiteCategoryDetails/LiteCategoryDetails.jsx';
import { $isDarkTheme } from '../../../models/authModel/index.js';
import { $currentFavs } from '../../../models/rbpLiteModel/index.js';

function LiteCategory() {
  const chosen_metric = useUnit($activeFilters).chosen_metrics[0];
  const changeActiveFilter = useUnit(changeActiveFilterEv);
  const [expanded, setExpanded] = useState(false);
  const isDark = useUnit($isDarkTheme);
  const [isFav, setIsFav] = useState(false);
  const currentFavs = useUnit($currentFavs);
  // FIXME if the template is wrong use indexCards to form items
  // const indexCards = useUnit($indexCards);

  const changeCategory = (value) => {
    if (isFav) setIsFav(false);
    if (chosen_metric === value) return;
    changeActiveFilter({
      field: 'chosen_metrics',
      value,
    });
    if (expanded) {
      setExpanded(false);
    }
  };

  const templateCategories = [
    {
      title: 'Стоимость аренды, тыс. тенге за кв. м.',
      icon: <RentIcon />,
      index_name: 'rent',
      info: '',
      value: 4345,
      avg: 5000,
      total: 10000,
    },
    {
      title: 'Постоянное население в зоне охвата, чел.',
      icon: <PopulationIcon />,
      info: '',
      index_name: 'population',
      value: 4345,
      avg: 5000,
      total: 10000,
    },
    {
      title: 'Работающие в зоне охвата, чел.',
      icon: <WorkingIcon />,
      info: '',
      index_name: 'working',
      value: 4345,
      avg: 5000,
      total: 10000,
    },
    {
      title: 'Кол-во домохозяйств в зоне охвата, шт.',
      icon: <HouseholdsIcon />,
      info: '',
      index_name: 'households',
      value: 4345,
      avg: 5000,
      total: 10000,
    },
    {
      title: 'Средний уровень дохода, тыс. тенге',
      icon: <IncomeIcon />,
      info: '',
      index_name: 'income',
      value: 4345,
      avg: 5000,
      total: 10000,
    },
    {
      title: 'Количество конкурентов, шт.',
      icon: <CompetitorsIcon />,
      info: '',
      index_name: 'competitors_cnt_B',
      value: 4345,
      avg: 5000,
      total: 10000,
    },
    {
      title: 'Потенциальный охват с учетом конкурентов, чел.',
      icon: <PotentialIcon />,
      info: '',
      index_name: 'potential',
      value: 4345,
      avg: 5000,
      total: 10000,
    },
    {
      title: 'Объемы трат, тыс. тенге',
      icon: <SpendingsIcon />,
      info: '',
      index_name: 'spendings',
      value: 4345,
      avg: 5000,
      total: 10000,
    },
    {
      title: 'Траффик, чел. в час',
      icon: <TrafficIcon />,
      info: '',
      index_name: 'traffic',
      value: 4345,
      avg: 5000,
      total: 10000,
    },
  ];

  return (
    // <div className={style.public_category}>
    <>
      <div
        className={[
          style.public_category_wrapper,
          expanded && style.expanded,
        ].join(' ')}
        style={{
          background: isDark ? '#1e2328' : 'white',
        }}
      >
        {templateCategories.map((item, index) => {
          return (
            <LiteCategoryItem
              key={`category-${item.index_name}-${index}`}
              chosenMetric={chosen_metric}
              expanded={expanded}
              changeCategory={changeCategory}
              isDark={isDark}
              isFav={isFav}
              {...item}
            />
          );
        })}
        {!expanded ? (
          <>
            <LiteCategoryItem
              disabled={currentFavs.length === 0}
              key="favorites"
              chosenMetric={chosen_metric}
              expanded={expanded}
              changeCategory={() => setIsFav(true)}
              isDark={isDark}
              icon={<StarOutlined />}
              index_name="favorites"
              isFav={isFav}
            />
            <Divider style={{ margin: '12px 0' }} />
            <div
              className={style.expand_button}
              onClick={() => setExpanded(!expanded)}
            >
              <DoubleLeftOutlined />
            </div>
          </>
        ) : (
          ''
        )}
      </div>
      <LiteCategoryDetails expanded={expanded} isFav={isFav} />
    </>
    // </div>
  );
}

function LiteCategoryItem(props) {
  const {
    title,
    index_name,
    icon,
    info,
    chosenMetric,
    avg,
    total,
    value,
    expanded,
    changeCategory,
    isDark,
    isFav,
    disabled,
  } = props;

  return (
    <Tooltip title={title} placement="left">
      <div
        className={[
          style.public_category_item,
          !isFav
            ? chosenMetric === index_name
              ? style.active
              : ''
            : index_name === 'favorites'
              ? style.active
              : '',
        ].join(' ')}
        onClick={
          !disabled
            ? () => {
                changeCategory(index_name);
              }
            : () => {}
        }
        style={{
          padding: expanded ? '10px 10px 20px 10px' : '10px',
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
      >
        <div
          className={style.public_category_item_top}
          style={{
            justifyContent: expanded ? 'space-between' : 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              columnGap: 10,
            }}
          >
            {icon}
            {expanded && (
              <div style={{ color: isDark ? 'white' : 'black' }}>{title}</div>
            )}
          </div>
          {expanded && (
            <InfoCircleOutlined className={style.public_category_info} />
          )}
          <div className={style.public_category_popup}>
            <div className={style.public_category_popup_title}>{title}</div>
            {info}
          </div>
        </div>
        {expanded ? (
          <div className={style.gradient_line}>
            <div
              className={style.city_avg}
              style={{
                left: `${Math.round((avg * 100) / total)}%`,
              }}
            >
              {avg} среднее по городу
            </div>
            <div
              className={style.category_value}
              style={{
                left: `${Math.round((value * 100) / total)}%`,
              }}
            >
              <div className={style.category_value_pointer}>
                <div
                  style={{ color: isDark ? 'white' : 'black' }}
                  className={style.category_value_num}
                >
                  {value}
                </div>
              </div>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    </Tooltip>
  );
}

export default LiteCategory;
