import booleanIntersects from '@turf/boolean-intersects';

export const districtsFilter = (activeFilters, item) => {
  if (activeFilters.district.length > 0) {
    if (!activeFilters.district.includes(item.properties.city_region_id))
      return false;
  }
  return true;
};

export const gradientFilter = (activeFilters, item, rbpLayer) => {
  const property = rbpLayer === 'index' ? 'index_main' : 'people_main';
  const maxValue = window.location.pathname === '/tezber' ? Infinity : 100;
  if (activeFilters.gradient.length > 0) {
    if (
      !activeFilters.gradient.some((gradient) => {
        const max = gradient.max === maxValue ? Infinity : gradient.max;

        if (typeof item.properties[property] !== 'number') return false;
        return (
          item.properties[property] >= gradient.min &&
          item.properties[property] < max
        );
      })
    ) {
      return false;
    }
  }
  return true;
};

export const polygonFilter = (polygons, item) => {
  if (polygons.length > 0) {
    if (!polygons.some((polygon) => booleanIntersects(polygon, item))) {
      return false;
    }
  }
  return true;
};

export const districtPolygonFilter = (district, polygons, item) => {
  if (
    !(
      district.includes(item.properties.city_region_id) ||
      polygons.some((polygon) => booleanIntersects(polygon, item))
    )
  ) {
    return false;
  }
  return true;
};
