import {
  generateBuckets,
  jenksBuckets,
  quantileBuckets,
  geometricProgressionBuckets,
  arithmeticProgressionBuckets,
  standardDeviationBuckets,
  equalIntervalBuckets,
} from 'geobuckets/dist/src/index.js';
import { gradient_dictionary } from '../dictionaries/gradient_dictionary.js';

export function get_thresholds(values, column_indices, mode = 10) {
  function safe_int(x) {
    try {
      return parseInt(x);
    } catch (error) {
      return null;
    }
  }

  function* pairwise(x) {
    let i = x[0];
    for (const j of x.slice(1)) {
      yield [i, j];
      i = j;
    }
  }

  function get_thresholds_(values, coefscustom = null) {
    let coefs = null;
    if (mode === 10) {
      coefs = [15, 15, 15, 15, 10, 10, 10, 7, 2, 1];
    } else if (mode === 5) {
      coefs = [30, 30, 20, 17, 3];
    }

    if (coefscustom) {
      coefs = coefscustom.slice();
    }

    if (!coefs) {
      throw new Error(
        "Mode should be 10 or 5. If it's different, you should provide coefscustom of the length equal to mode"
      );
    }

    const REPEAT_THRESHOLD_VALUE = 5;
    const REPEAT_THRESHOLD_PERCENT = 0.33;
    const MIN_MAX_VALUE = 100;
    const ignore_repeats_mode =
      values.filter((v) => safe_int(v) <= REPEAT_THRESHOLD_VALUE).length >
        REPEAT_THRESHOLD_PERCENT * values.length &&
      Math.max(...values.map((v) => safe_int(v))) >= MIN_MAX_VALUE;

    values = values
      .map((x) => safe_int(x))
      .filter(
        (x) => x && x > (ignore_repeats_mode ? REPEAT_THRESHOLD_VALUE : 0)
      );

    if (values.length === 0) {
      return {
        [`threshold_${mode}`]: mode + 1,
      };
    }

    let res = [];
    let colors = [];
    if (new Set(values).size < mode) {
      values = [...new Set(values)].sort((a, b) => a - b);
      const ts = Array(mode).fill(null);
      for (const [i, v] of values.entries()) {
        let start_insert = 0;
        if (v / Math.max(values[0], 1) >= 100) {
          start_insert = Math.trunc(7 * (mode / 10));
        } else if (v / Math.max(values[0], 1) >= 10) {
          start_insert = Math.trunc(4 * (mode / 10));
        }
        for (let j = start_insert; j < mode; j++) {
          if (ts[j] !== null && ts[j] > v) {
            ts.splice(j, 0, v);
            break;
          }
          if (ts[j] === null) {
            ts[j] = v;
            break;
          }
        }
      }
      // const res = [];
      for (let i = 0; i < ts.length; i++) {
        if (ts[i] === null) {
          res.push(null);
          continue;
        }
        res.push(ts[i]);
      }
    } else {
      const qs = values.slice().sort((a, b) => b - a);
      // let res = [];
      let i = 0;
      const coefs_reversed = coefs.slice().reverse();
      while (coefs_reversed.length > 0) {
        const coef =
          (coefs_reversed[0] / coefs.reduce((acc, val) => acc + val, 0)) * 100;
        coefs_reversed.shift();
        let prev = null;
        let size = 0;
        while (i < qs.length) {
          size++;
          if ((size / (qs.length - i)) * 100 >= coef && prev !== qs[i]) {
            res.push(qs[i]);
            break;
          }
          prev = qs[i];
          i++;
        }
      }
      res = res
        .slice(0, -1)
        .filter((r) => r)
        .slice(0, 9)
        .reverse();
      if (ignore_repeats_mode) {
        res.unshift(REPEAT_THRESHOLD_VALUE);
      }
      if (res.length < mode - 1) {
        const pairs = res.slice(0, -1).map((r, i) => Math.abs(r - res[i + 1]));
        let mean_step = pairs.reduce((acc, val) => acc + val, 0) / pairs.length;
        if (mean_step < 1) {
          mean_step = 1;
        }
        while (res.length < mode - 1) {
          res.push(Math.trunc(res[res.length - 1] + mean_step));
        }
      }
      colors = Array.from({ length: mode }, (_, i) => i + 1);
    }

    const thresholds = {};
    for (let i = 0; i < res.length; i++) {
      if (i === mode - 1) {
        break;
      }
      if (res[i] === null) {
        continue;
      }
      thresholds[`threshold_${i + 2}`] = res[i];
    }
    console.log(` GET RES TRASH ___________________: ${res} `);
    return { thresholds, colors };
  }

  if (!column_indices) {
    return [get_thresholds_(values)];
  }
  return column_indices.map((ind) => get_thresholds_(values[ind]));
}

export function getThresholds(hexagons) {
  let result = get_thresholds(
    // Array.from(new Set(hexagons.map((item) => item.properties.index_main))),
    hexagons.map((item) => item.properties.index_main),
    false
  );
  result = Object.values(result[0].thresholds).map((item) => Math.floor(item));
  if (!result.includes(100)) {
    result.push(100);
  }

  return result;
}

const filterBuckets = (hexagons, buckets) => {
  const pureBuckets = [];
  buckets.forEach((bucket) => {
    if (!pureBuckets.includes(bucket)) pureBuckets.push(bucket);
  });

  const bucketsToDelete = [];

  pureBuckets.forEach((item, index) => {
    if (index !== pureBuckets.length - 1) {
      const min = item;
      const max = pureBuckets[index + 1];
      if (!hexagons.some((hexagon) => hexagon >= min && hexagon < max)) {
        if (!bucketsToDelete.includes(min)) {
          bucketsToDelete.push(min);
        }
      }
    }
  });
  return pureBuckets.filter((item) => !bucketsToDelete.includes(item));
};

export async function getGeoBuckets(hexagons, algorithm, rbpLayer) {
  const property = rbpLayer === 'index' ? 'index_main' : 'people_main';
  const data = hexagons.map((item) => item.properties[property]);
  // .filter((item) => item);
  let numClasses = 10;
  let buckets;
  if (data.length < 10) {
    numClasses = data.length;
  }
  switch (algorithm) {
    case 'EQI':
      buckets = await equalIntervalBuckets(data, numClasses);
      buckets = buckets.map((item) => Math.floor(item));
      console.log('algorithm EQI: - ', buckets);
      break;
    case 'JNK':
      buckets = await jenksBuckets(data, numClasses);
      buckets = buckets.map((item) => Math.floor(item));
      console.log('algorithm JNK: - ', buckets);
      break;
    case 'QNT':
      // FIXME OLD
      // buckets = get_thresholds(data, false, 11);
      // // console.log('get_thresholds: - ', buckets)
      // buckets = Object.values(buckets[0].thresholds).map((item) =>
      //   Math.floor(item)
      // );

      // FIXME NEW WITH METHOD FROM LIBRARY
      buckets = await quantileBuckets(data, numClasses);
      buckets = buckets.map((item) => Math.floor(item));
      console.log('algorithm quantileBuckets: - ', buckets);
      break;
    // case 'QNT': // 17ms
    //   buckets = await quantileBuckets(data, numClasses);
    //   buckets = buckets.map((item) => Math.floor(item));
    //   break;
    // case 'GPG': // 7.45ms
    //   buckets = await geometricProgressionBuckets(data, numClasses);
    //   buckets = buckets.map((item) => Math.floor(item));
    //   break;
    // case 'APG': // 7.38ms
    //   buckets = await arithmeticProgressionBuckets(data, numClasses);
    //   buckets = buckets.map((item) => Math.floor(item));
    //   break;
    // case 'STD': // 68ms
    //   buckets = await standardDeviationBuckets(data, numClasses);
    //   buckets = buckets.map((item) => Math.floor(item));
    //   break;

    default:
      buckets = get_thresholds(data, false);
      buckets = Object.values(buckets[0].thresholds).map((item) =>
        Math.floor(item)
      );
      break;
  }

  if (buckets[buckets.length - 1] !== 100) {
    buckets[buckets.length - 1] = buckets[buckets.length - 1] + 1;
  }
  const filteredBuckets = filterBuckets(data, buckets);
  console.log('algorithm filtered buckets: - ', filteredBuckets);
  return filteredBuckets;
}

export const getLessColors = (bucketsLength, darkMode) => {
  const theme = window.location.pathname === '/public' ? 'public' : darkMode ? 'dark' : 'light';
  return gradient_dictionary[theme][bucketsLength];
};
