import React from 'react';
import { useUnit } from 'effector-react';
import {
  $wildberriesChosenDots,
  $wildberriesDots,
} from '../../../models/tezberModel/index.js';
import TezberDotsPreset from './TezberDotsPreset.jsx';
import { layerColors } from '../../../models/tezberModel/utils.js';

function WildberriesLayer() {
  const dots = useUnit($wildberriesDots);
  const tableChosenDots = useUnit($wildberriesChosenDots);

  return (
    <TezberDotsPreset
      dots={dots}
      tableChosenDots={tableChosenDots}
      layerName="wildberries"
      color={layerColors.wildberries}
    />
  );
}

export default WildberriesLayer;
