import { sample } from 'effector';
import {
  $chartFilters,
  $currentFavDetails,
  $currentFavs,
  $detailsData,
  $isPreset,
  $profileModal,
} from './stores.js';
import {
  changeChartFiltersEv,
  changeCurrentFavsEv,
  toggleIsPresetEv,
  toggleProfileModalEv,
} from './events.js';
import {
  $activeFilters,
  changeActiveFilterEv,
} from '../activeFiltersModel/index.js';
import { $zoom7Hexagons, getZeroDataFx } from '../zoom7Model/index.js';
import { formatDetailsData, generateDetailByMetric } from './utils.js';

$isPreset.on(toggleIsPresetEv, (state, payload) => {
  if (typeof payload === 'boolean') return payload;
  return !state;
});

$profileModal.on(toggleProfileModalEv, (state, payload) => {
  if (typeof payload === 'boolean') return payload;
  return !state;
});

sample({
  source: $zoom7Hexagons,
  clock: $activeFilters.updates,
  fn: (source, { chosen_metrics }) =>
    formatDetailsData(source, chosen_metrics[0]),
  target: $detailsData,
});

sample({
  clock: getZeroDataFx.doneData,
  fn: (clock) => formatDetailsData(clock.polygons, 'competitors_cnt_B'),
  target: $detailsData,
});

sample({
  source: $currentFavs,
  clock: changeCurrentFavsEv,
  fn: (source, clock) => {
    if (source.includes(clock)) {
      return source.filter((item) => item !== clock);
    }
    return [...source, clock];
  },
  target: $currentFavs,
});

sample({
  source: $zoom7Hexagons,
  clock: $currentFavs.updates,
  fn: (source, clock) => generateDetailByMetric(source, clock),
  target: $currentFavDetails,
});

sample({
  source: $chartFilters,
  clock: changeChartFiltersEv,
  fn: (source, clock) => {
    if (
      source.some((item) => {
        return Object.keys(item).every((field) => item[field] === clock[field]);
      })
    ) {
      return source.filter((item) => {
        return !Object.keys(item).every(
          (field) => item[field] === clock[field]
        );
      });
    }
    return [...source, clock];
  },
  target: $chartFilters,
});

sample({
  source: $zoom7Hexagons,
  clock: $chartFilters.updates,
  filter: (source, clock) => clock.length > 0,
  fn: (source, clock) => {
    //   FIXME mutate hexagons based on chartFilters
  },
  // target: $zoom7Hexagons
});

sample({
  clock: changeActiveFilterEv,
  filter: (clock) =>
    window.location.pathname === '/lite' && clock.field === 'chosen_metrics',
  fn: () => [],
  target: $chartFilters,
});
