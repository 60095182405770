import React from 'react';
import { useUnit } from 'effector-react';
import style from '../RbpLanding.module.css';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import { $language } from '../../../models/rbpLandingModel/index.js';
import how_it_works_img_1 from '../../../assets/RbpLandingImages/HowItWorks/how_it_works_img_1.png';
import how_it_works_img_2 from '../../../assets/RbpLandingImages/HowItWorks/how_it_works_img_2.png';
import how_it_works_img_3 from '../../../assets/RbpLandingImages/HowItWorks/how_it_works_img_3.png';
import how_it_works_img_4 from '../../../assets/RbpLandingImages/HowItWorks/how_it_works_img_4.png';
import how_it_works_img_small_1 from '../../../assets/RbpLandingImages/HowItWorks/how_it_works_img_small_1.png';
import how_it_works_img_small_2 from '../../../assets/RbpLandingImages/HowItWorks/how_it_works_img_small_2.png';
import how_it_works_img_small_3 from '../../../assets/RbpLandingImages/HowItWorks/how_it_works_img_small_3.png';
import how_it_works_img_small_4 from '../../../assets/RbpLandingImages/HowItWorks/how_it_works_img_small_4.png';
import useWindowSize from '../../../hooks/useWindowSize.js';

function HowItWorks() {
  const language = useUnit($language);
  const [width, height] = useWindowSize();

  const how_it_works = [
    {
      title: rbp_landing_translation[language].how_it_works_card_1_title,
      desc: rbp_landing_translation[language].how_it_works_card_1_desc,
      img: width > 1200 ? how_it_works_img_1 : how_it_works_img_small_1,
    },
    {
      title: rbp_landing_translation[language].how_it_works_card_2_title,
      desc: rbp_landing_translation[language].how_it_works_card_2_desc,
      img: width > 1200 ? how_it_works_img_2 : how_it_works_img_small_2,
    },
    {
      title: rbp_landing_translation[language].how_it_works_card_3_title,
      desc: rbp_landing_translation[language].how_it_works_card_3_desc,
      img: width > 1200 ? how_it_works_img_3 : how_it_works_img_small_3,
    },
    {
      title: rbp_landing_translation[language].how_it_works_card_4_title,
      desc: rbp_landing_translation[language].how_it_works_card_4_desc,
      img: width > 1200 ? how_it_works_img_4 : how_it_works_img_small_4,
    },
  ];

  return (
    <div className={style.container}>
      <div className={style.how_it_works} id="how_it_works">
        {/* <div className={style.section_name}> */}
        {/*  {rbp_landing_translation[language].how_it_works_section} */}
        {/* </div> */}
        <div className={style.section_title}>
          {rbp_landing_translation[language].how_it_works_title}
        </div>
        <div className={style.section_subtitle}>
          {rbp_landing_translation[language].how_it_works_subtitle}
        </div>
        <div className={style.how_it_works_cards_wrapper}>
          {how_it_works.map((item, index) => {
            return (
              <div
                key={`how_it_works-${index}`}
                className={style.how_it_works_card}
              >
                <div className={style.how_it_works_card_img_wrapper}>
                  <div className={style.how_it_works_img_bg_number}>
                    {index + 1}
                  </div>
                  <img src={item.img} className={style.how_it_works_card_img} />
                </div>
                <div className={style.how_it_works_card_title}>
                  {item.title}
                </div>
                <div className={style.how_it_works_card_desc}>{item.desc}</div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
