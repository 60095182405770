import React from 'react';
import { useUnit } from 'effector-react';
import style from '../RbpLanding.module.css';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import { $language } from '../../../models/rbpLandingModel/index.js';
import ContactsForm from './ContactsForm.jsx';
import contacts_icon_1 from '../../../assets/RbpLandingImages/Contacts/contacts_icon_1.svg';
import contacts_icon_2 from '../../../assets/RbpLandingImages/Contacts/contacts_icon_2.svg';
import contacts_icon_3 from '../../../assets/RbpLandingImages/Contacts/contacts_icon_3.svg';
import contacts_map from '../../../assets/RbpLandingImages/Contacts/contacts_map.png';
import contacts_map_small from '../../../assets/RbpLandingImages/Contacts/contacts_map_small.png';
import useWindowSize from '../../../hooks/useWindowSize.js';

function Contacts() {
  const language = useUnit($language);
  const [width, height] = useWindowSize();

  return (
    <div className={style.container}>
      <div className={style.contacts} id="contacts">
        <div className={style.section_title}>
          {rbp_landing_translation[language].contacts_title}
        </div>
        <div className={style.section_subtitle}>
          {rbp_landing_translation[language].contacts_subtitle}
        </div>
        <div className={style.contacts_wrapper}>
          <ContactsForm />
          <div className={style.contacts_info_block}>
            <div className={style.contacts_info} style={{ marginTop: 20 }}>
              <img src={contacts_icon_1} />
              <div>
                <div className={style.contacts_info_title}>
                  {rbp_landing_translation[language].contacts_address}
                </div>
                <div className={style.contacts_info_value}>
                  {rbp_landing_translation[language].contacts_address_value}
                </div>
              </div>
            </div>
            <div className={style.contacts_info}>
              <img src={contacts_icon_2} />
              <div>
                <div className={style.contacts_info_title}>
                  {rbp_landing_translation[language].contacts_phone}
                </div>
                <div className={style.contacts_info_value}>
                  +7 706 640 55 95
                </div>
              </div>
            </div>
            <div className={style.contacts_info}>
              <img src={contacts_icon_1} />
              <div>
                <div className={style.contacts_info_title}>Email</div>
                <div className={style.contacts_info_value}>
                  info@qala-ai.com
                </div>
              </div>
            </div>
            <img src={width > 1200 ? contacts_map : contacts_map_small} className={style.contacts_map}/>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contacts;
