import { createEffect } from 'effector';
import tezberData from '../../data/tezberSources/tezberData.json';
import tezberOurData from '../../data/tezberOurData.json';
import tezber_population from '../../data/tezberSources/tezber_population.json';
import express_139 from '../../data/tezberSources/139_Express.json';
import CDEK from '../../data/tezberSources/CDEK.json';
import Jana_Post from '../../data/tezberSources/Jana_Post.json';
import Kimigo from '../../data/tezberSources/Kimigo.json';
import Ozon from '../../data/tezberSources/Ozon.json';
import Wildberries from '../../data/tezberSources/Wildberries.json';

export const getTezberDataFx = createEffect().use(async () => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve({
        dots: tezberData.features,
        // ourDots: tezberOurData.features,
        population: tezber_population.features,
        express: express_139.features,
        cdek: CDEK.features,
        janaPost: Jana_Post.features,
        kimigo: Kimigo.features,
        ozon: Ozon.features,
        wildberries: Wildberries.features,
      });
    }, 1000);
  });
});
