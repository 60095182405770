import React from 'react';
import { Button, Form, Input } from 'antd';
import { useUnit } from 'effector-react';
import {
  $language,
  sendRegRequestEv,
  sendRegRequestFx,
} from '../../../models/rbpLandingModel/index.js';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import style from '../RbpLanding.module.css';
import useWindowSize from '../../../hooks/useWindowSize.js';

const { TextArea } = Input;

function ContactsForm(props) {
  const language = useUnit($language);
  const [form] = Form.useForm();
  const sendRegRequest = useUnit(sendRegRequestEv);
  const loading = useUnit(sendRegRequestFx.pending);
  const [width, height] = useWindowSize();

  const phone = Form.useWatch('phone', form);

  const onFinish = (values) => {
    sendRegRequest(values);
    form.resetFields();
  };

  const setInitPhone = () => {
    if (!phone) {
      form.setFieldValue('phone', '+7(');
    }
  };

  const onValuesChange = (changedValues, allValues) => {
    if (changedValues.phone) {
      if (phone?.length > changedValues.phone.length) {
        if (!changedValues.phone.includes('+7(') && phone.includes('+7(')) {
          form.setFieldValue('phone', phone);
        }
        return;
      }
      if (
        !/^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/g.test(changedValues.phone) &&
        /^\+7\(\d{3}\)\d{3}-\d{2}-\d{2}$/g.test(phone)
      ) {
        form.setFieldValue('phone', phone);
        return;
      }
      if (/[a-zA-Z]/g.test(changedValues.phone)) {
        form.setFieldValue(
          'phone',
          changedValues.phone.replace(/[a-zA-Z]/g, '')
        );
        return;
      }
      if (!changedValues.phone.includes('+7(')) {
        form.setFieldValue('phone', `+7(${changedValues.phone}`);
        return;
      }
      if (
        /\+7\(\d{3}/g.test(changedValues.phone) &&
        !/\+7\(\d{3}\)/g.test(changedValues.phone)
      ) {
        form.setFieldValue('phone', `${changedValues.phone})`);
        return;
      }
      if (
        /\+7\(\d{3}\)\d{3}/g.test(changedValues.phone) &&
        !/\+7\(\d{3}\)\d{3}-/g.test(changedValues.phone)
      ) {
        form.setFieldValue('phone', `${changedValues.phone}-`);
        return;
      }
      if (
        /\+7\(\d{3}\)\d{3}-\d{2}/g.test(changedValues.phone) &&
        !/\+7\(\d{3}\)\d{3}-\d{2}-/g.test(changedValues.phone)
      ) {
        form.setFieldValue('phone', `${changedValues.phone}-`);
      }
    }
  };

  return (
    <Form
      layout="vertical"
      form={form}
      onFinish={onFinish}
      style={{
        width: width > 1200 ? '50%' : '100%',
      }}
      validateTrigger="onBlur"
      onValuesChange={onValuesChange}
    >
      <Form.Item
        style={{ marginBottom: '20px' }}
        rules={[
          {
            required: true,
            message: rbp_landing_translation[language].contacts_form_name_error,
          },
        ]}
        label={
          <label className={style.contacts_form_label}>
            {rbp_landing_translation[language].contacts_form_name}
          </label>
        }
        name="name"
      >
        <Input
          className={style.contacts_form_input}
          placeholder={
            rbp_landing_translation[language].contacts_form_name_placeholder
          }
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        label={
          <label className={style.contacts_form_label}>
            {rbp_landing_translation[language].contacts_form_company}
          </label>
        }
        name="company"
      >
        <Input
          className={style.contacts_form_input}
          placeholder={
            rbp_landing_translation[language].contacts_form_company_placeholder
          }
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        rules={[
          {
            required: true,
            message:
              rbp_landing_translation[language].contacts_form_phone_error,
          },
          () => ({
            validator(_, value) {
              if (!/\+7(\(\d{3}\)\d{3}-\d{2}-\d{2})/g.test(value)) {
                return Promise.reject(new Error('Введите корректный номер'));
              }
              return Promise.resolve();
            },
          }),
        ]}
        label={
          <label className={style.contacts_form_label}>
            {rbp_landing_translation[language].contacts_form_phone}
          </label>
        }
        name="phone"
      >
        <Input
          className={style.contacts_form_input}
          placeholder="+7 (___)___-__-__"
          onFocus={setInitPhone}
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        rules={[
          {
            required: true,
            message:
              rbp_landing_translation[language].contacts_form_email_error,
          },
          {
            type: 'email',
            message:
              rbp_landing_translation[language]
                .contacts_form_email_wrong_format,
          },
        ]}
        label={<label className={style.contacts_form_label}>Email</label>}
        name="email"
      >
        <Input
          className={style.contacts_form_input}
          placeholder="email@email.com"
        />
      </Form.Item>
      <Form.Item
        style={{ marginBottom: '20px' }}
        label={
          <label className={style.contacts_form_label}>
            {rbp_landing_translation[language].contacts_form_message}
          </label>
        }
        name="message"
      >
        <TextArea
          className={style.contacts_form_input}
          autoSize={{ minRows: 4.7273, maxRows: 4.7273 }}
          placeholder={
            rbp_landing_translation[language].contacts_form_message_placeholder
          }
        />
      </Form.Item>
      <Button
        className={style.contacts_form_button}
        onClick={form.submit}
        loading={loading}
      >
        {rbp_landing_translation[language].contacts_form_button}
      </Button>
    </Form>
  );
}

export default ContactsForm;
