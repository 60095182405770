import { createStore } from 'effector';
import { gradient_colors } from '../../dictionaries/gradient_dictionary.js';

const initialState = {
  buckets: [],
  colors:
    window.location.pathname === '/public'
      ? gradient_colors.public
      : gradient_colors.light,
};

const blockedInitialState = {
  zoom_8: {
    '0-10': true,
    '10-20': true,
    '20-30': true,
    '30-40': true,
    '40-50': true,
    '50-60': true,
    '60-70': true,
    '70-80': true,
    '80-90': true,
    '90-100': true,
  },
  zoom_9: {
    '0-10': true,
    '10-20': true,
    '20-30': true,
    '30-40': true,
    '40-50': true,
    '50-60': true,
    '60-70': true,
    '70-80': true,
    '80-90': true,
    '90-100': true,
  },
  zoom_10: {
    '0-10': true,
    '10-20': true,
    '20-30': true,
    '30-40': true,
    '40-50': true,
    '50-60': true,
    '60-70': true,
    '70-80': true,
    '80-90': true,
    '90-100': true,
  },
};

export const $gradientBuckets = createStore(initialState);
$gradientBuckets.watch((state) => console.log('$gradientBuckets', state));

export const $blockedBuckets = createStore(blockedInitialState);
$blockedBuckets.watch((state) => console.log('$blockedBuckets', state));

const initAlgorithm = window.location.pathname === '/public' ? 'JNK' : 'EQI';
export const $bucketAlgorithm = createStore(initAlgorithm);
$bucketAlgorithm.watch((state) => console.log('$bucketAlgorithm', state));
