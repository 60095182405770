import { createStore } from 'effector';

export const $isPreset = createStore(true);

export const $detailsData = createStore([]);
$detailsData.watch((state) => console.log('$detailsData', state));

export const $profileModal = createStore(false);

export const $currentFavs = createStore([]);
$currentFavs.watch((state) => console.log('$currentFavs', state));

export const $currentFavDetails = createStore([]);
$currentFavDetails.watch((state) => console.log('$currentFavDetails', state));

export const $chartFilters = createStore([]);
$chartFilters.watch((state) => console.log('$chartFilters', state));
