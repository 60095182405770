import { sample } from 'effector';
import { $language } from '../authModel/index.js';
import { $businessTypeTree } from './stores.js';
import { rbp_business_types } from '../../dictionaries/rbp_dicts.js';

sample({
  source: $businessTypeTree,
  clock: $language,
  fn: (source, clock) => {
    return source.map((item) => {
      return {
        ...item,
        title: rbp_business_types[item.key.split('business_')[1]][clock],
        children: item.children.map((child) => {
          return {
            ...child,
            title: rbp_business_types[child.key.split('business_')[1]][clock],
          };
        }),
      };
    });
  },
  target: $businessTypeTree,
});
