import { createStore } from 'effector';

const initialStore = {
  draw_polygon: [],
  quarter: 11,
  district: [],
  zoom8_hex: {
    id: '',
    centerCoords: [],
  },
  zoom9_hex: {
    id: '',
    centerCoords: [],
  },
  zoom10_hex: {
    id: '',
    centerCoords: [],
  },
  excludedIndexes: [],
  gradient: [],
  chosen_metrics: [],
  business_type: 401,
  groups: [],
};

if (
  window.location.pathname === '/public' ||
  window.location.pathname === '/lite'
) {
  initialStore.chosen_metrics = ['competitors_cnt_B'];
}

export const $activeFilters = createStore(initialStore);
$activeFilters.watch((state) => console.log('$activeFilters', state));

export const $chartFilters = createStore([]);
$chartFilters.watch((state) => console.log('$chartFilters', state));
