import React, { useState } from 'react';
import { useUnit } from 'effector-react';
import { PlusOutlined } from '@ant-design/icons';
import style from '../RbpLanding.module.css';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import { $language } from '../../../models/rbpLandingModel/index.js';

function Faq() {
  const language = useUnit($language);

  const collapses = [
    {
      title: rbp_landing_translation[language].faq_question_1_title,
      items: [rbp_landing_translation[language].faq_question_1_items],
    },
    {
      title: rbp_landing_translation[language].faq_question_2_title,
      items: [rbp_landing_translation[language].faq_question_2_items],
    },
    {
      title: rbp_landing_translation[language].faq_question_3_title,
      items: [rbp_landing_translation[language].faq_question_3_items],
    },
    {
      title: rbp_landing_translation[language].faq_question_4_title,
      items: [rbp_landing_translation[language].faq_question_4_items],
    },
    {
      title: rbp_landing_translation[language].faq_question_5_title,
      items: [rbp_landing_translation[language].faq_question_5_items],
    },
    {
      title: rbp_landing_translation[language].faq_question_6_title,
      items: [rbp_landing_translation[language].faq_question_6_items],
    },
    {
      title: rbp_landing_translation[language].faq_question_7_title,
      items: [rbp_landing_translation[language].faq_question_7_items],
    },
    {
      title: rbp_landing_translation[language].faq_question_8_title,
      items: [rbp_landing_translation[language].faq_question_8_items],
    },
    {
      title: rbp_landing_translation[language].faq_question_9_title,
      items: [rbp_landing_translation[language].faq_question_9_items],
    },
    {
      title: rbp_landing_translation[language].faq_question_10_title,
      items: [rbp_landing_translation[language].faq_question_10_items],
    },
  ];

  return (
    <div className={style.container}>
      <div className={style.faq} id={'faq'}>
        {/* <div className={style.section_name}> */}
        {/*  {rbp_landing_translation[language].faq_section} */}
        {/* </div> */}
        <div className={style.section_title}>
          {rbp_landing_translation[language].faq_title}
        </div>
        <div className={style.faq_questions_wrapper}>
          {collapses.map((item, index) => {
            return (
              <CustomCollapse
                key={`request-collapse-${index}`}
                collapseIndex={index}
                {...item}
              />
            );
          })}
        </div>
      </div>
    </div>
  );
}

function CustomCollapse(props) {
  const { title, items, collapseIndex } = props;

  const [open, setOpen] = useState(false);

  return (
    <div
      className={style.faq_collapse}
      onClick={() => setOpen(!open)}
      style={{
        color: open ? '#1780BC' : '#11023B',
      }}
    >
      <div className={style.faq_collapse_title_wrapper}>
        <div className={style.faq_collapse_title}>{title}</div>
        <PlusOutlined
          className={style.faq_collapse_icon}
          rotate={open ? 45 : 0}
        />
      </div>
      <ul
        className={[
          style.faq_collapse_item_wrapper,
          open ? style.faq_collapse_open : '',
        ].join(' ')}
      >
        {items.map((item, index) => {
          return (
            <li
              key={`collapse-item-${collapseIndex}-${index}`}
              className={style.faq_collapse_item}
            >
              {item}
            </li>
          );
        })}
      </ul>
    </div>
  );
}

export default Faq;
