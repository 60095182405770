import React, { useRef, useState } from 'react';
import { useUnit } from 'effector-react';
import { Space, Table } from 'antd';
import { StarFilled, StarOutlined } from '@ant-design/icons';
import style from './LiteCategoryDetails.module.css';
import {
  $currentFavDetails,
  $currentFavs,
  $detailsData,
  changeCurrentFavsEv,
} from '../../../models/rbpLiteModel/index.js';
import LiteColumnsChart from './LiteColumnsChart.jsx';
import LitePieChart from './LitePieChart.jsx';
import LiteBarChart from './LiteBarChart.jsx';
import { $isDarkTheme } from '../../../models/authModel/index.js';
import CursorIcon from '../CursorIcon/CursorIcon.jsx';

function LiteCategoryDetails({ expanded, isFav }) {
  const detailsData = useUnit($detailsData);
  const currentFavs = useUnit($currentFavs);
  const changeCurrentFavs = useUnit(changeCurrentFavsEv);
  const currentFavDetails = useUnit($currentFavDetails);

  const isDark = useUnit($isDarkTheme);

  if (expanded) {
    return null;
  }

  const toggleFav = (metric) => {
    changeCurrentFavs(metric);
  };

  return (
    <div
      className={style.details_modal}
      style={{ background: isDark ? '#1e2328' : 'white' }}
    >
      <Space direction="vertical">
        {(isFav ? currentFavDetails : detailsData).map((item, index) => {
          return (
            <DetailItem
              key={`detail-${index}-${item.metric}`}
              item={item}
              currentFavs={currentFavs}
              toggleFav={toggleFav}
            />
          );
        })}
      </Space>
    </div>
  );
}

function DetailItem(props) {
  const { item, currentFavs, toggleFav } = props;

  const [hide, setHide] = useState(false);

  setTimeout(() => {
    setHide(true);
  }, 6000);

  let display;
  switch (item.type) {
    case 'table':
      display = (
        <>
          <Table
            dataSource={item.data}
            columns={item.columns}
            pagination={false}
          />
          {item.subTable && (
            <Table
              dataSource={item.subTable.data}
              columns={item.subTable.columns}
              pagination={false}
            />
          )}
        </>
      );
      break;
    case 'columns':
      display = <LiteColumnsChart data={item.data} metrics={item.metric} />;
      break;
    case 'pie':
      display = <LitePieChart data={item.data} metrics={item.metric} />;
      break;
    case 'bar':
      display = <LiteBarChart data={item.data} metrics={item.metric} />;
      break;
  }

  return (
    <div className={style.details_item} onMouseEnter={() => setHide(true)}>
      {item.type !== 'table' && !hide && <CursorIcon />}
      {item.title && (
        <div className={style.details_item_title_wrapper}>
          <div className={style.details_item_title}>{item.title}</div>{' '}
          {currentFavs.includes(item.metric) ? (
            <StarFilled onClick={() => toggleFav(item.metric)} />
          ) : (
            <StarOutlined onClick={() => toggleFav(item.metric)} />
          )}
        </div>
      )}
      {display}
    </div>
  );
}

export default LiteCategoryDetails;
