import React from 'react';
import { Button, Divider, Popover, Radio, Select, Space } from 'antd';
import { useUnit } from 'effector-react';
import { RightOutlined, SearchOutlined } from '@ant-design/icons';
import { useLocation } from 'react-router-dom';
import style from './PublicBusinessType.module.css';
import { public_index_dictionary } from '../../dictionaries/public_index_dictionary.js';
import {
  $chosenZoom7Hexagon,
  $clickCoords,
  $publicBusinessType,
  changePublicBusinessTypeEv,
} from '../../models/zoom7Model/index.js';

function PublicBusinessType() {
  const publicBusinessType = useUnit($publicBusinessType);
  const changePublicBusinessType = useUnit(changePublicBusinessTypeEv);
  const chosenZoom7Hexagon = useUnit($chosenZoom7Hexagon);
  const clickCoords = useUnit($clickCoords);
  const { pathname } = useLocation();

  const onGetFull = () => {
    let url = 'https://rbp-dev.qala-ai.com/payment';
    if (clickCoords) {
      url += `?lat=${clickCoords.lat}&lng=${clickCoords.lng}`;
    }
    window.open(url, '_blank');
  };

  return (
    <div className={style.public_business_type}>
      <div className={style.public_business_type_title}>Тип бизнеса</div>
      <Select
        value={publicBusinessType}
        className={style.public_business_type_select}
        onChange={changePublicBusinessType}
        suffixIcon={<SearchOutlined />}
        options={Object.keys(public_index_dictionary)
          .filter((item) => /competitors_cnt_B\d{1}/g.test(item))
          .map((item) => ({
            label: public_index_dictionary[item],
            value: item,
          }))}
      />
      {pathname !== '/lite' ? (
        <>
          <Divider style={{ margin: '15px 0' }} />
          <div className={style.public_business_desc}>
            Также вы можете получить больше данных с высокой детализацией
          </div>
          <Button
            type="primary"
            // disabled={!chosenZoom7Hexagon}
            className={style.get_full_button}
            onClick={onGetFull}
          >
            Получить больше данных
          </Button>
        </>
      ) : (
        ''
      )}
    </div>
  );
}

export default PublicBusinessType;
