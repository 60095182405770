import { createEvent } from 'effector';

export const savePresetEv = createEvent();

export const deletePresetEv = createEvent();

export const changeShowPresetsEv = createEvent();

export const updateDataPresetEv = createEvent();

export const resetLayerToUpdateEv = createEvent();

export const changeShowModalEv = createEvent();

export const changeSortByEv = createEvent();

export const changeSortOrderEv = createEvent();

export const changeActiveAreasEv = createEvent();

export const changePresetAreaToEditEv = createEvent();

export const resetPresetAreaToEditEv = createEvent();

export const createDuplicateEv = createEvent();

export const resetPresetStateEv = createEvent();

export const changeAreaNameEv = createEvent();

export const deleteAreaEv = createEvent();

export const recolorPresetEv = createEvent();

export const changeEditNameEv = createEvent();

export const changeEditColorEv = createEvent();

export const resetEditNameEv = createEvent();

export const resetEditColorEv = createEvent();

export const applyPresetFiltersEv = createEvent();
