import React from 'react';
import { useUnit } from 'effector-react';
import LiteCategory from './LiteCategory/LiteCategory.jsx';
import PublicCategory from '../PublicCategory/PublicCategory.jsx';
import PublicBusinessType from '../PublicBusinessType/PublicBusinessType.jsx';
import { $isPreset } from '../../models/rbpLiteModel/index.js';

function LiteMapWidgetsManager() {
  const isPreset = useUnit($isPreset);

  if (isPreset) {
    return <LiteCategory />;
  }
  return (
    <>
      <PublicBusinessType />
      <PublicCategory />
    </>
  );
}

export default LiteMapWidgetsManager;
