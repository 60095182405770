import { Button, Dropdown, Tooltip } from 'antd';
import Icon, { AppstoreOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from './MapStyleSelector.module.css';
import { setMapLoadedEv } from '../../models/mapModel/index.js';
import voyager from '../../assets/voyager.png';
import dark from '../../assets/dark.png';
import light from '../../assets/light.png';
import streets from '../../assets/streets.png';
import satellite from '../../assets/satellite.png';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $language } from '../../models/authModel/index.js';

function LayersSvg() {
  return (
    <svg
      width="20px"
      height="20px"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M11.553 1.10557C11.8346 0.964809 12.1659 0.964809 12.4475 1.10557L22.4475 6.10557C22.7862 6.27496 23.0002 6.62123 23.0002 7C23.0002 7.37877 22.7862 7.72504 22.4475 7.89443L12.4475 12.8944C12.1659 13.0352 11.8346 13.0352 11.553 12.8944L1.55303 7.89443C1.21425 7.72504 1.00024 7.37877 1.00024 7C1.00024 6.62123 1.21425 6.27496 1.55303 6.10557L11.553 1.10557ZM4.23631 7L12.0002 10.882L19.7642 7L12.0002 3.11803L4.23631 7Z"
        fill="#000000"
      />
      <path
        d="M1.10579 16.5528C1.35278 16.0588 1.95345 15.8586 2.44743 16.1055L12.0002 20.8819L21.553 16.1055C22.047 15.8586 22.6477 16.0588 22.8946 16.5528C23.1416 17.0467 22.9414 17.6474 22.4474 17.8944L12.4474 22.8944C12.1659 23.0352 11.8345 23.0352 11.553 22.8944L1.55301 17.8944C1.05903 17.6474 0.858803 17.0467 1.10579 16.5528Z"
        fill="#000000"
      />
      <path
        d="M2.44743 11.1055C1.95345 10.8586 1.35278 11.0588 1.10579 11.5528C0.858803 12.0467 1.05903 12.6474 1.55301 12.8944L11.553 17.8944C11.8345 18.0352 12.1659 18.0352 12.4474 17.8944L22.4474 12.8944C22.9414 12.6474 23.1416 12.0467 22.8946 11.5528C22.6477 11.0588 22.047 10.8586 21.553 11.1055L12.0002 15.8819L2.44743 11.1055Z"
        fill="#000000"
      />
    </svg>
  );
}
function LayersIcon() {
  return <Icon component={LayersSvg} />;
}

const changeMapStyle = ({ key }) => {
  setMapLoadedEv(false);

  let link = `mapbox://styles/mapbox/${key}`;
  if (key === 'voyager') {
    link = 'https://basemaps.cartocdn.com/gl/voyager-gl-style/style.json';
  }
  window.map.setStyle(link);
};

function MapStyleSelector({ className }) {
  const language = useUnit($language);

  const dropDownItems = [
    {
      label: (
        <div data-styleid="streets-v12" className={style.select_item}>
          <img src={streets} style={{ marginRight: '10px' }} />
          {ru_en_page_dictionary.map_style_streets[language]}
        </div>
      ),
      key: 'streets-v12',
    },
    {
      label: (
        <div data-styleid="voyager" className={style.select_item}>
          <img src={voyager} style={{ marginRight: '10px' }} />
          {ru_en_page_dictionary.map_style_voyager[language]}
        </div>
      ),
      key: 'voyager',
    },
    {
      label: (
        <div data-styleid="dark-v11" className={style.select_item}>
          <img src={dark} style={{ marginRight: '10px' }} />
          {ru_en_page_dictionary.map_style_dark[language]}
        </div>
      ),
      key: 'dark-v11',
    },
    {
      label: (
        <div data-styleid="light-v11" className={style.select_item}>
          <img src={light} style={{ marginRight: '10px' }} />
          {ru_en_page_dictionary.map_style_light[language]}
        </div>
      ),
      key: 'light-v11',
    },
    {
      label: (
        <div data-styleid="satellite-v9" className={style.select_item}>
          <img src={satellite} style={{ marginRight: '10px' }} />
          {ru_en_page_dictionary.map_style_satellite[language]}
        </div>
      ),
      key: 'satellite-v9',
    },
  ];

  return (
    <Dropdown
      menu={{ items: dropDownItems, onClick: changeMapStyle }}
      className={[className, style.layer_selector].join(' ')}
      placement="right"
    >
      <Button
        icon={<AppstoreOutlined style={{ fontSize: '20px' }} />}
        className={style.layer_selector_button}
      />
    </Dropdown>
  );
}

export default MapStyleSelector;
