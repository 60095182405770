import React from 'react';
import { useUnit } from 'effector-react';
import {
  $cdekChosenDots,
  $cdekDots,
} from '../../../models/tezberModel/index.js';
import TezberDotsPreset from './TezberDotsPreset.jsx';
import { layerColors } from '../../../models/tezberModel/utils.js';

function CdekLayer() {
  const dots = useUnit($cdekDots);
  const tableChosenDots = useUnit($cdekChosenDots);

  return (
    <TezberDotsPreset
      dots={dots}
      tableChosenDots={tableChosenDots}
      layerName="cdek"
      color={layerColors.cdek}
    />
  );
}

export default CdekLayer;
