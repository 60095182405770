import { createBrowserRouter } from 'react-router-dom';
import App from '../App.jsx';
import MainPage from '../pages/MainPage/MainPage.jsx';
import Login from '../pages/Login/Login.jsx';
import Register from '../pages/Register/Register.jsx';
import MainPageRestyle from '../pages/MainPage/MainPageRestyle.jsx';
import UserList from '../pages/UserList/UserList.jsx';
import UserView from '../pages/UserView/UserView.jsx';
import DemoMainPage from '../pages/MainPage/DemoMainPage.jsx';
import Chat from '../pages/Chat/Chat.jsx';
import ChatV2 from '../pages/ChatV2/Chat.jsx';
import Banking from '../pages/Banking/Chat.jsx';
import Crowdfunding from '../pages/Crowdfunding/Chat.jsx';
import Elnet from '../pages/Elnet/Chat.jsx';
import Gassup from '../pages/Gassup/Chat.jsx';
import Heatsys from '../pages/Heatsys/Chat.jsx';
import Landrules from '../pages/Landrules/Chat.jsx';
import Realestate from '../pages/Realestate/Chat.jsx';
import Watersup from '../pages/Watersup/Chat.jsx';
import RbpLanding from '../pages/RbpLanding/RbpLanding.jsx';
import Tezber from '../pages/Tezber/Tezber.jsx';
import MainLanding from '../pages/RbpLanding/subPages/MainLanding.jsx';
import PaymentPage from '../pages/RbpLanding/subPages/PaymentPage.jsx';
import RequestsList from '../pages/RequestsList/RequestsList.jsx';
import RbpLitePage from '../pages/MainPage/RbpLitePage.jsx';

const routes = createBrowserRouter([
  {
    path: '/',
    element: <App />,
    children: [
      { path: 'root', element: <MainPageRestyle /> },
      { path: 'user-list', element: <UserList /> },
      { path: 'user/:id', element: <UserView /> },
      { path: 'public', element: <DemoMainPage /> },
      // { path: 'lite', element: <RbpLitePage /> },
      { path: 'chat', element: <Chat /> },
      { path: 'chat-v2', element: <ChatV2 /> },
      { path: 'banking', element: <Banking /> },
      { path: 'crowdfunding', element: <Crowdfunding /> },
      { path: 'realestate', element: <Realestate /> },
      { path: 'elnet', element: <Elnet /> },
      { path: 'heatsys', element: <Heatsys /> },
      { path: 'gassup', element: <Gassup /> },
      { path: 'watersup', element: <Watersup /> },
      { path: 'landrules', element: <Landrules /> },
      { path: 'tezber', element: <Tezber /> },
      { path: 'requests-list', element: <RequestsList /> },
    ],
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/register',
    element: <Register />,
  },
  {
    path: '/',
    element: <RbpLanding />,
    children: [
      { path: '/main', element: <MainLanding /> },
      { path: '/payment', element: <PaymentPage /> },
    ],
  },
  // {
  //   path: '/landing',
  //   element: <Landing />,
  // },
]);

export default routes;
