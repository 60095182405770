import React from 'react';
import { useUnit } from 'effector-react';
import {
  $expressChosenDots,
  $expressDots,
} from '../../../models/tezberModel/index.js';
import TezberDotsPreset from './TezberDotsPreset.jsx';
import { layerColors } from '../../../models/tezberModel/utils.js';

function ExpressLayer() {
  const dots = useUnit($expressDots);
  const tableChosenDots = useUnit($expressChosenDots);

  return (
    <TezberDotsPreset
      dots={dots}
      tableChosenDots={tableChosenDots}
      layerName="express"
      color={layerColors.express}
    />
  );
}

export default ExpressLayer;
