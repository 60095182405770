import React, { useRef } from 'react';
import { Card, Tooltip } from 'antd';
import Chart from 'react-apexcharts';
import { useUnit } from 'effector-react';
import { ru_en_page_dictionary } from '../../../dictionaries/ru_en_page_dictionary.js';
import { $isDarkTheme } from '../../../models/authModel/index.js';
import {
  $activeLayers,
  $tezberChartData,
} from '../../../models/tezberModel/index.js';

const bars_dict = {
  tezber_dots_layer: 'Tezber',
  express: '139 Express',
  cdek: 'СДЭК',
  janaPost: 'Jana Post',
  kimigo: 'Kimigo',
  ozon: 'Ozon',
  wildberries: 'Wildberries',
};

function DiffChart() {
  const chartRef = useRef();
  const darkMode = useUnit($isDarkTheme);
  const tezberChartData = useUnit($tezberChartData);
  const activeLayers = useUnit($activeLayers);

  const source = { ...tezberChartData };

  Object.keys(tezberChartData).forEach((key) => {
    if (!activeLayers.includes(key)) {
      delete source[key];
    }
  });

  const options = {
    plotOptions: {
      bar: {
        borderRadius: 4,
        borderRadiusApplication: 'end',
        dataLabels: {
          position: 'top',
        },
      },
    },
    chart: {
      // stacked: true,
      // offsetY: -20,
      toolbar: {
        show: false,
      },
    },
    yaxis: {
      labels: {
        style: {
          colors: darkMode ? 'white' : '#2D9CDB',
        },
        formatter: (value) => {
          if (value > 1000) {
            return `${Math.round(value / 1000)}K`;
          }
          return value;
        },
      },
    },
    xaxis: {
      categories: Object.keys(source).map((item) => bars_dict[item]),
      labels: {
        show: true,
        style: {
          fontSize: '14px',
          colors: darkMode ? 'white' : '#2D9CDB',
        },
        formatter: (value) => value,
      },
    },
    // tooltip: {
    // 	theme: darkMode ? 'dark' : 'light',
    // 	x: {
    // 		formatter: (value, opts) => {
    // 			if (value === 'i0') {
    // 				return ru_en_page_dictionary.resilience_index[language];
    // 			}
    // 			return indexChartData[opts.dataPointIndex].title;
    // 		},
    // 	},
    // 	y: {
    // 		title: {
    // 			formatter: () => `${ru_en_page_dictionary.radar_tooltip[language]}:`,
    // 		},
    // 	},
    // },
    legend: {
      onItemClick: {
        toggleDataSeries: false,
      },
      labels: {
        colors: darkMode ? 'white' : '#2D9CDB',
      },
    },
    dataLabels: {
      enabled: false,
    },
    tooltip: {
      theme: darkMode ? 'dark' : 'light',
    },
  };

  const series = [
    {
      name: 'Общее население',
      data: Object.keys(source).map((item) => source[item].pop_total),
    },
    {
      name: 'Целевая аудитория (18 - 60 лет)',
      data: Object.keys(source).map((item) => source[item].pop_18_60),
    },
  ];

  console.log('series', series);

  return (
    <Card style={{ height: '100%' }} styles={{ body: { height: '99%' } }}>
      <Chart
        ref={chartRef}
        options={options}
        series={series}
        type="bar"
        width="100%"
        height="100%"
        // padding="15"
      />
    </Card>
  );
}

export default DiffChart;
