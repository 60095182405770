import { sample } from 'effector';
import { union } from '@turf/turf';
import notification from 'antd/es/notification';
import {
  changeActiveAreasEv,
  changeAreaNameEv,
  changeEditColorEv,
  changeEditNameEv,
  changePresetAreaToEditEv,
  changeShowModalEv,
  changeShowPresetsEv,
  changeSortByEv,
  changeSortOrderEv,
  createDuplicateEv,
  deleteAreaEv,
  deletePresetEv,
  recolorPresetEv,
  resetEditColorEv,
  resetEditNameEv,
  resetLayerToUpdateEv,
  resetPresetAreaToEditEv,
  resetPresetStateEv,
  savePresetEv,
  updateDataPresetEv,
} from './events.js';
import {
  $activeAreas,
  $colorEdit,
  $dataPresets,
  $layerToUpdate,
  $nameEdit,
  $presetAreaToEdit,
  $presetsToColor,
  $showModal,
  $showPresets,
  $sortBy,
  $sortOrder,
  $userAppointedPreset,
  $userPresets,
} from './stores.js';
import {
  $zoom10Hexagons,
  $zoom10StartHexagons,
  calculateExcByPresetEv,
  calculateMetricByPresetEv,
} from '../zoom10Model/index.js';
import { $indexChartData } from '../indexCardsModel/index.js';
import { $bucketAlgorithm, $gradientBuckets } from '../gradientModel/index.js';
import { changeLayerVisibility } from '../../utils/mapbox-utils.js';
import {
  $activeFilters,
  $chartFilters,
  updateFiltersByPresetEv,
} from '../activeFiltersModel/index.js';
import {
  formatPreset,
  formatPresets,
  recalculateDataPresets,
  separateHexagonsToAreas,
  separateHexByPolygon,
  sortDataPresets,
  validateCreatePreset,
} from '../../utils/data-presets-utils.js';
import {
  createDataPresetFx,
  deleteDataPresetFx,
  getDataPresetFx,
  getDataPresetsFx,
  getPresetThresholdFx,
  getUserAppointedPresetFx,
  updateDataPresetFx,
} from './effects.js';
import { hideLoaderEv, showLoaderEv } from '../webSocketModel/index.js';
import { $isDarkTheme, $language } from '../authModel/index.js';
import { getLessColors } from '../../utils/gradient-utils.js';
import { gradient_colors } from '../../dictionaries/gradient_dictionary.js';
import { ru_en_page_dictionary } from '../../dictionaries/ru_en_page_dictionary.js';
import { $isochroneStore } from '../isochroneModel/index.js';
import { $zoom9Hexagons, $zoom9StartHexagons } from '../zoom9Model/index.js';
import { wsGetRBPData } from '../../utils/webSocketConfig.js';

$showPresets.on(changeShowPresetsEv, (state) => !state);

$layerToUpdate.reset(resetLayerToUpdateEv);

$showModal.on(changeShowModalEv, (state, payload) => {
  if (typeof payload === 'boolean') {
    return payload;
  }
  return !state;
});

$sortBy.on(changeSortByEv, (state, payload) => payload);
$sortOrder.on(changeSortOrderEv, (state, payload) => payload);

$presetAreaToEdit.reset(resetPresetAreaToEditEv);

$nameEdit.reset(resetEditNameEv);
$colorEdit.reset(resetEditColorEv);

sample({
  source: $dataPresets,
  clock: changeShowPresetsEv,
  filter: (source) => source.length === 0,
  target: [getDataPresetsFx, showLoaderEv],
});

sample({
  source: [$zoom9Hexagons, $activeFilters, $isochroneStore, $chartFilters],
  clock: savePresetEv,
  filter: ([hexagons, activeFilters, isochroneStore], clock) => {
    return (
      validateCreatePreset(clock, activeFilters, isochroneStore).length === 0
    );
  },
  fn: ([hexagons, activeFilters, isochroneStore, chartFilters], clock) => {
    // let border;
    // hexagons.forEach((item) => {
    //   if (!border) border = item;
    //   else border = union(border, item);
    // });
    return {
      ...clock,
      areas: separateHexagonsToAreas(
        hexagons,
        activeFilters.draw_polygon.filter(
          (item) => item.geometry.type !== 'Point'
        ),
        activeFilters.district,
        clock.name,
        isochroneStore
      ),
      activeFilters: {
        isochrone: isochroneStore.map((item) => {
          return {
            area: item,
            point: window.draw
              .getAll()
              .features.find(
                (feature) =>
                  feature.geometry.type === 'Point' &&
                  feature.properties.name === item.properties.name
              ),
          };
        }),
        chosen_metrics: activeFilters.chosen_metrics,
        district: activeFilters.district,
        draw_polygon: activeFilters.draw_polygon
          .filter((item) => item.geometry.type !== 'Point')
          .map((item, index) => {
            return {
              ...item,
              properties: {
                name: `Area ${index + 1}`,
              },
            };
          }),
        excludedIndexes: activeFilters.excludedIndexes,
        socdem_groups: activeFilters.groups,
        chart_filters: chartFilters,
      },
      service: 'rbp',
    };
  },
  target: [createDataPresetFx, showLoaderEv],
});

sample({
  source: [$zoom9Hexagons, $activeFilters, $language, $isochroneStore],
  clock: savePresetEv,
  filter: ([hexagons, activeFilters, _, isochroneStore], clock) => {
    return (
      validateCreatePreset(clock, activeFilters, isochroneStore).length > 0
    );
  },
  fn: ([hexagons, activeFilters, language, isochroneStore], clock) => {
    const errors = validateCreatePreset(clock, activeFilters, isochroneStore);
    errors.forEach((error) => {
      notification.error({
        message: ru_en_page_dictionary.error[language],
        description: ru_en_page_dictionary[error][language],
        placement: 'topRight',
        className: 'notification',
      });
    });
  },
});

sample({
  clock: deletePresetEv,
  target: [deleteDataPresetFx, showLoaderEv],
});

sample({
  source: $dataPresets,
  clock: deleteDataPresetFx.doneData,
  fn: (source, clock) => {
    return source.filter((item) => item.id !== clock);
  },
  target: [$dataPresets, hideLoaderEv],
});

sample({
  source: $userPresets,
  clock: deleteDataPresetFx.doneData,
  fn: (source, clock) => {
    return source.filter((item) => item.id !== clock);
  },
  target: [$userPresets, hideLoaderEv],
});

sample({
  source: [$zoom9StartHexagons, $language, $bucketAlgorithm],
  clock: getDataPresetsFx.doneData,
  fn: ([startHexagons, language, algorithm], clock) => {
    return {
      data: formatPresets(clock.all_presets, startHexagons, language),
      algorithm,
    };
  },
  target: getPresetThresholdFx,
});

sample({
  source: [$zoom9StartHexagons, $language, $bucketAlgorithm],
  clock: getDataPresetsFx.doneData,
  fn: ([startHexagons, language, algorithm], clock) => {
    return {
      data: formatPresets(clock.user_presets, startHexagons, language),
      algorithm,
      activeAreas: [],
      user: true,
    };
  },
  target: getPresetThresholdFx,
});

sample({
  source: [$isDarkTheme, $dataPresets],
  clock: getPresetThresholdFx.doneData,
  filter: (source, { result, user }) => !user,
  fn: ([darkMode, dataPresets], { result }) => {
    const clock = result;
    if (clock.length === 1) {
      let colors;
      if (clock[0].gradient.length - 1 < 10) {
        colors = getLessColors(clock[0].gradient.length - 1, darkMode);
      } else {
        colors = gradient_colors[darkMode ? 'dark' : 'light'];
      }
      return [
        ...dataPresets.filter((preset) => preset.id !== clock[0].id),
        {
          ...clock[0],
          gradient: {
            buckets: clock[0].gradient,
            colors,
          },
        },
      ];
    }
    return clock.map((preset) => {
      let colors;
      if (preset.gradient.length - 1 < 10) {
        colors = getLessColors(preset.gradient.length - 1, darkMode);
      } else {
        colors = gradient_colors[darkMode ? 'dark' : 'light'];
      }
      return {
        ...preset,
        gradient: {
          buckets: preset.gradient,
          colors,
        },
      };
    });
  },
  target: [$dataPresets, hideLoaderEv],
});

sample({
  source: [$isDarkTheme, $userPresets],
  clock: getPresetThresholdFx.doneData,
  filter: (source, { result, user }) => user,
  fn: ([darkMode, userPresets], { result }) => {
    const clock = result;
    if (clock.length === 1) {
      let colors;
      if (clock[0].gradient.length - 1 < 10) {
        colors = getLessColors(clock[0].gradient.length - 1, darkMode);
      } else {
        colors = gradient_colors[darkMode ? 'dark' : 'light'];
      }
      return [
        ...userPresets.filter((preset) => preset.id !== clock[0].id),
        {
          ...clock[0],
          gradient: {
            buckets: clock[0].gradient,
            colors,
          },
        },
      ];
    }
    return clock.map((preset) => {
      let colors;
      if (preset.gradient.length - 1 < 10) {
        colors = getLessColors(preset.gradient.length - 1, darkMode);
      } else {
        colors = gradient_colors[darkMode ? 'dark' : 'light'];
      }
      return {
        ...preset,
        gradient: {
          buckets: preset.gradient,
          colors,
        },
      };
    });
  },
  target: [$userPresets, hideLoaderEv],
});

sample({
  source: [$zoom9StartHexagons, $language, $bucketAlgorithm],
  clock: createDataPresetFx.doneData,
  fn: ([startHexagons, language, algorithm], clock) => {
    return {
      data: [formatPreset(clock, startHexagons, language)],
      algorithm,
    };
  },
  target: getPresetThresholdFx,
});

sample({
  source: [$zoom9StartHexagons, $language, $bucketAlgorithm],
  clock: createDataPresetFx.doneData,
  fn: ([startHexagons, language, algorithm], clock) => {
    return {
      data: [formatPreset(clock, startHexagons, language)],
      algorithm,
      activeAreas: [],
      user: true,
    };
  },
  target: getPresetThresholdFx,
});

sample({
  clock: getDataPresetFx.doneData,
  fn: (clock) => {
    console.log('getDataPresetFx:: result', clock);
  },
});

sample({
  source: [
    $zoom9StartHexagons,
    $activeFilters,
    $nameEdit,
    $colorEdit,
    $isochroneStore,
    $presetAreaToEdit,
  ],
  clock: updateDataPresetEv,
  fn: (
    [
      hexagons,
      activeFilters,
      nameEdit,
      colorEdit,
      isochroneStore,
      presetAreaToEdit,
    ],
    clock
  ) => {
    const { areaNames } = clock;
    let isochrone =
      isochroneStore.length > 0
        ? isochroneStore.map((item) => {
            return {
              area: item,
              point: window.draw
                .getAll()
                .features.find(
                  (point) =>
                    point.geometry.type === 'Point' &&
                    point.properties.name === item.properties.name
                ),
            };
          })
        : [];

    let updatedActiveFilters;
    if (presetAreaToEdit.area) {
      updatedActiveFilters = {
        ...clock.preset.activeFilters,
        draw_polygon: [
          ...clock.preset.activeFilters.draw_polygon.filter((item) => {
            item.properties.name !== presetAreaToEdit.area;
          }),
          ...activeFilters.draw_polygon,
        ],
      };
    } else {
      updatedActiveFilters = {
        ...clock.preset.activeFilters,
        ...activeFilters,
        draw_polygon: [
          ...clock.preset.activeFilters.draw_polygon.filter(
            (item) =>
              !activeFilters.draw_polygon
                .map((polygon) => polygon.id)
                .includes(item.id)
          ),
          ...activeFilters.draw_polygon,
        ],
      };
    }

    const updatedAreas = separateHexagonsToAreas(
      hexagons,
      updatedActiveFilters.draw_polygon,
      updatedActiveFilters.district,
      nameEdit || clock.preset.name,
      isochroneStore
    );
    if (Object.keys(areaNames).some((item) => item !== areaNames[item])) {
      Object.keys(areaNames).forEach((area) => {
        if (areaNames[area] !== area) {
          updatedAreas[areaNames[area]] = updatedAreas[area];
          delete updatedAreas[area];
          updatedActiveFilters.draw_polygon =
            updatedActiveFilters.draw_polygon.map((polygon) => {
              if (polygon.id === area) {
                return {
                  ...polygon,
                  properties: {
                    name: areaNames[area],
                  },
                };
              }
              return polygon;
            });
          if (isochrone.length > 0) {
            isochrone = isochrone.map((isochroneItem) => {
              if (isochroneItem.area.properties.name === area) {
                return {
                  area: {
                    ...isochroneItem.area,
                    properties: {
                      name: areaNames[area],
                    },
                  },
                  point: {
                    ...isochroneItem.point,
                    properties: {
                      name: areaNames[area],
                    },
                  },
                };
              }
              return isochroneItem;
            });
          }
        }
      });
    }

    debugger;

    return {
      id: clock.preset.id,
      data: {
        name: nameEdit || clock.preset.name,
        color: colorEdit
          ? colorEdit.metaColor
            ? `#${colorEdit.metaColor.toHex()}`
            : colorEdit
          : clock.preset.color,
        areas: updatedAreas,
        activeFilters: {
          chosen_metrics: updatedActiveFilters.chosen_metrics,
          district: updatedActiveFilters.district,
          draw_polygon: updatedActiveFilters.draw_polygon,
          excludedIndexes: updatedActiveFilters.excludedIndexes,
          isochrone,
        },
      },
    };
  },
  target: updateDataPresetFx,
});

sample({
  source: [$zoom9StartHexagons, $language, $bucketAlgorithm],
  clock: updateDataPresetFx.doneData,
  fn: ([startHexagons, language, algorithm], clock) => {
    console.log('updateDataPresetFx:: result', clock);
    return {
      data: [formatPreset(clock, startHexagons, language)],
      algorithm,
    };
  },
  target: [getPresetThresholdFx],
});

sample({
  clock: updateDataPresetFx.doneData,
  fn: (clock) => clock.id,
  target: $layerToUpdate,
});

// sample({
//   source: [$dataPresets, $sortBy, $sortOrder],
//   clock: [$sortBy.updates, $sortOrder.updates],
//   fn: sortDataPresets,
//   target: $dataPresets,
// });

sample({
  source: $activeAreas,
  clock: changeActiveAreasEv,
  fn: (source, clock) => {
    if (Array.isArray(clock)) {
      if (clock.every((item) => source.includes(item))) {
        return source.filter((item) => !clock.includes(item));
      }
      return Array.from(new Set([...source, ...clock]));
    }
    if (source.includes(clock)) {
      return source.filter((item) => item !== clock);
    }
    return [...source, clock];
  },
  target: $activeAreas,
});

sample({
  source: [$dataPresets, $language, $bucketAlgorithm],
  clock: $activeAreas.updates,
  fn: ([presets, language, algorithm], activeAreas) => {
    return {
      data: recalculateDataPresets(presets, activeAreas, language),
      algorithm,
      activeAreas,
    };
  },
  target: getPresetThresholdFx,
});

sample({
  clock: changePresetAreaToEditEv,
  target: $presetAreaToEdit,
});

sample({
  source: $dataPresets,
  clock: [$presetAreaToEdit.updates, resetPresetStateEv],
  filter: (source, clock) =>
    clock.preset &&
    source.find((preset) => preset.name === clock.preset).activeFilters
      .excludedIndexes.length > 0,
  fn: (source, clock) =>
    source.find((preset) => preset.name === clock.preset).activeFilters
      .excludedIndexes,
  target: calculateExcByPresetEv,
});

sample({
  source: $dataPresets,
  clock: [$presetAreaToEdit.updates, resetPresetStateEv],
  filter: (source, clock) =>
    clock.preset &&
    source.find((preset) => preset.name === clock.preset).activeFilters
      .chosen_metrics.length > 0,
  fn: (source, clock) =>
    source.find((preset) => preset.name === clock.preset).activeFilters,
  target: calculateMetricByPresetEv,
});

sample({
  source: $dataPresets,
  clock: [$presetAreaToEdit.updates, resetPresetStateEv],
  filter: (source, clock) =>
    clock.preset &&
    source.find((preset) => preset.name === clock.preset).activeFilters
      .chosen_metrics.length === 0 &&
    source.find((preset) => preset.name === clock.preset).activeFilters
      .excludedIndexes.length === 0,
  target: updateFiltersByPresetEv,
});

sample({
  source: $dataPresets,
  clock: createDuplicateEv,
  fn: (source, clock) => {
    const chosenPreset = source.find((item) => item.id === clock.id);
    const copiesLength = source.filter((item) =>
      item.name.includes(`${clock.name}(copy)`)
    ).length;
    return {
      ...chosenPreset,
      name: `${chosenPreset.name}(copy) ${
        copiesLength > 0 ? copiesLength + 1 : ''
      }`,
      areas: Object.fromEntries(
        Object.entries(chosenPreset.areas).map(([key, value]) => [
          `${key}(copy)${copiesLength > 0 ? copiesLength + 1 : ''}`,
          value,
        ])
      ),
      activeFilters: {
        ...chosenPreset.activeFilters,
        draw_polygon: chosenPreset.activeFilters.draw_polygon.map((item) => {
          return {
            ...item,
            id: `${item.id}(copy)${copiesLength > 0 ? copiesLength + 1 : ''}`,
          };
        }),
      },
    };
  },
  target: createDataPresetFx,
});

sample({
  clock: changeAreaNameEv,
  fn: (clock) => {
    let data = { ...clock.preset };
    const polygonToChange = clock.preset.activeFilters.draw_polygon.find(
      (item) => item.id === clock.areaId
    );
    if (polygonToChange) {
      polygonToChange.properties.name = clock.name;
      data = {
        ...data,
        activeFilters: {
          ...clock.preset.activeFilters,
          draw_polygon: [
            ...clock.preset.activeFilters.draw_polygon.filter(
              (item) => item.id !== clock.areaId
            ),
            polygonToChange,
          ],
        },
      };
    } else {
      data = {
        ...data,
        areas: {
          ...clock.preset.areas,
          [clock.name]: clock.preset.areas[clock.areaId],
        },
      };
      delete data.areas[clock.areaId];
    }
    return {
      id: clock.preset.id,
      data,
    };
  },
  target: updateDataPresetFx,
});

sample({
  clock: deleteAreaEv,
  fn: (clock) => {
    return {
      id: clock.preset.id,
      data: {
        ...clock.preset,
        areas: Object.fromEntries(
          Object.entries(clock.preset.areas).filter(
            ([area, value]) => area !== clock.areaId
          )
        ),
        activeFilters: {
          ...clock.preset.activeFilters,
          draw_polygon: [
            ...clock.preset.activeFilters.draw_polygon.filter(
              (item) => item.id !== clock.areaId
            ),
          ],
        },
      },
    };
  },
  target: updateDataPresetFx,
});

sample({
  source: $presetsToColor,
  clock: recolorPresetEv,
  filter: (source, clock) => {
    return window.map.getLayer(`preset_layer-${clock}`).visibility !== 'none';
  },
  fn: (source, clock) => {
    if (source.includes(clock)) {
      return source.filter((item) => item !== clock);
    }
    return [...source, clock];
  },
  target: $presetsToColor,
});

sample({
  clock: changeEditNameEv,
  target: $nameEdit,
});

sample({
  clock: changeEditColorEv,
  target: $colorEdit,
});

sample({
  clock: getUserAppointedPresetFx.doneData,
  fn: (clock) => {
    const allZoomIds = [];
    Object.keys(clock.preset.areas).forEach((area) => {
      clock.preset.areas[area].forEach((zoom_id) => allZoomIds.push(zoom_id));
    });
    wsGetRBPData({
      zoom_ids: allZoomIds,
    });
    return clock;
  },
  target: $userAppointedPreset,
});
