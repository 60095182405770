import { useUnit } from 'effector-react';
import {
  InfoCircleOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons';
import style from '../InfographicsTabs.module.css';
import {
  $isDarkTheme,
  $language,
  $userInfo,
} from '../../../models/authModel/index.js';
import index_names_dynamics from '../../../dictionaries/index_names_dymanics.json';
import { changeModalOpenEv } from '../../../models/indexCardsModel/index.js';
import {
  wsSendRBPAddFavoritesChart,
  wsSendRBPRemoveFavoritesChart,
} from '../../../utils/webSocketConfig.js';
import { ru_en_page_dictionary } from '../../../dictionaries/ru_en_page_dictionary.js';
import { formatNumber } from '../../../utils/format-utils.js';
import {
  $activeFilters,
  calculateByMetricEv,
  changeActiveFilterEv,
} from '../../../models/activeFiltersModel/index.js';

export default function InfoCards(props) {
  const { data, title, expanded } = props;
  const darkMode = useUnit($isDarkTheme);
  const language = useUnit($language);
  const changeActiveFilters = useUnit(changeActiveFilterEv);
  const calculateByMetric = useUnit(calculateByMetricEv);
  const { chosen_metrics } = useUnit($activeFilters);

  const userFavs = useUnit($userInfo).rbp_chart_favorites;

  const changeModalOpen = useUnit(changeModalOpenEv);

  const addFavorite = () => {
    wsSendRBPAddFavoritesChart([...userFavs, +title]);
  };

  const removeFavorite = () => {
    wsSendRBPRemoveFavoritesChart([+title]);
  };

  const onQuestionClick = () => {
    console.log('card_index: -', title);
    changeModalOpen(`d${title}`);
  };

  const onCardClick = (category) => {
    changeActiveFilters({
      field: 'chosen_metrics',
      value: category,
    });
    calculateByMetric();
  };

  return (
    <div
      className={[
        style.chart_wrapper,
        expanded ? style.chart_expanded : '',
      ].join(' ')}
      style={{
        background: darkMode ? '#181A1F' : '#F8F8F8',
      }}
    >
      <div className={style.chart_header_wrapper}>
        <div className={style.chart_title}>
          {index_names_dynamics[`d${title}`][`name_${language}`]}
        </div>
        {userFavs.includes(+title) ? (
          <StarFilled onClick={removeFavorite} />
        ) : (
          <StarOutlined onClick={addFavorite} />
        )}
        <div
          className={style.modal_button}
          onClick={onQuestionClick}
          style={{
            color: darkMode ? 'white' : '#717171',
          }}
        >
          <InfoCircleOutlined />
        </div>
      </div>
      <div className={style.info_cards_wrapper}>
        {Object.keys(data).map((item) => {
          return (
            <div
              className={[
                style.info_card,
                chosen_metrics.includes(item) ? style.info_card_active : '',
              ].join(' ')}
              style={{
                borderColor: darkMode ? 'white' : 'black',
              }}
              key={`info_card_${item}`}
              onClick={() => onCardClick(item)}
            >
              <div
                className={style.info_card_title}
                style={{ color: darkMode ? 'white' : 'black' }}
              >
                {ru_en_page_dictionary[item][language]}
              </div>
              <div
                className={style.info_card_value}
                style={{ color: darkMode ? 'white' : 'black' }}
              >{`${formatNumber(data[item])} ${
                ru_en_page_dictionary.tenge[language]
              }`}</div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
