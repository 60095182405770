import React, { useEffect, useState } from 'react';
import { Button, Dropdown } from 'antd';
import { ArrowRightOutlined, RightOutlined } from '@ant-design/icons';
import { useUnit } from 'effector-react';
import { useLocation, useNavigate } from 'react-router-dom';
import style from '../RbpLanding.module.css';
import header_logo from '../../../assets/RbpLandingImages/Header/header_logo.svg';
import header_close from '../../../assets/RbpLandingImages/Header/header_close.svg';
import { rbp_landing_translation } from '../../../dictionaries/rbp_landing_translation.js';
import linkedin from '../../../assets/RbpLandingImages/Header/linkedin.svg';
import {
  $language,
  $menuModal,
  changeLanguageEv,
  changeMenuModalEv,
  changeShowRequestModalEv,
} from '../../../models/rbpLandingModel/index.js';

function HeaderSmallMenu() {
  const menuModal = useUnit($menuModal);
  const changeMenuModal = useUnit(changeMenuModalEv);
  const language = useUnit($language);
  const changeLanguage = useUnit(changeLanguageEv);
  const [isOpen, setIsOpen] = useState(false);
  const changeShowRequestModal = useUnit(changeShowRequestModalEv);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const scrollTo = (section) => {
    if (pathname !== '/main') {
      navigate('/main');
    }
    setTimeout(() => {
      document.getElementById(section).scrollIntoView();
      changeMenuModal(false);
    }, 0);
  };

  const onClose = () => {
    changeMenuModal(false);
  };

  useEffect(() => {
    if (menuModal) {
      setIsOpen(menuModal);
    } else {
      setTimeout(() => {
        setIsOpen(menuModal);
      }, 500);
    }
  }, [menuModal]);

  const languageItems = [
    {
      key: 'en',
      label: 'Eng',
    },
    {
      key: 'ru',
      label: 'Рус',
    },
  ];

  return (
    <div
      className={[
        style.header_menu_modal_wrapper,
        menuModal ? style.animation_appear : style.animation_disappear,
        isOpen ? style.show : style.hide,
      ].join(' ')}
    >
      <div className={style.header_menu_overlay} onClick={onClose} />
      <div className={style.header_menu_modal}>
        <div className={style.header_menu_top}>
          <img src={header_logo} style={{ height: 25 }} />
          <img src={header_close} onClick={onClose} />
        </div>
        <ul className={style.menu_wrapper}>
          <li>
            <div
              className={style.menu_item}
              onClick={() => scrollTo('features')}
            >
              {rbp_landing_translation[language].menu_item_1}
            </div>
          </li>
          <li>
            <div
              className={style.menu_item}
              onClick={() => scrollTo('how_it_works')}
            >
              {rbp_landing_translation[language].menu_item_2}
            </div>
          </li>
          <li>
            <div
              className={style.menu_item}
              onClick={() => scrollTo('openmap')}
            >
              {rbp_landing_translation[language].menu_item_3}
            </div>
          </li>
          <li>
            <div className={style.menu_item} onClick={() => scrollTo('prices')}>
              {rbp_landing_translation[language].menu_item_4}
            </div>
          </li>
          <li>
            <div className={style.menu_item} onClick={() => scrollTo('faq')}>
              {rbp_landing_translation[language].menu_item_5}
            </div>
          </li>
          <li>
            <div
              className={style.menu_item}
              onClick={() => scrollTo('contacts')}
            >
              {rbp_landing_translation[language].menu_item_6}
            </div>
          </li>
        </ul>
        <div className={style.header_right}>
          {/* <Dropdown */}
          {/*  menu={{ */}
          {/*    items: languageItems, */}
          {/*    onClick: ({ key }) => { */}
          {/*      changeLanguage(key); */}
          {/*    }, */}
          {/*  }} */}
          {/* > */}
          {/*  <div className={style.header_language}> */}
          {/*    {language === 'en' ? 'Eng' : 'Рус'} */}
          {/*  </div> */}
          {/* </Dropdown> */}
          <Button
            type="primary"
            className={style.header_button}
            icon={<ArrowRightOutlined />}
            iconPosition="end"
            onClick={changeShowRequestModal}
          >
            {rbp_landing_translation[language].promo_button}
          </Button>
        </div>
      </div>
    </div>
  );
}

export default HeaderSmallMenu;
