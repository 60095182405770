import React from 'react';
import { Collapse, Space, Table } from 'antd';
import Icon from '@ant-design/icons';
import { useUnit } from 'effector-react';
import style from './RentSuggestions.module.css';
import { $isDarkTheme } from '../../../models/authModel/index.js';

function LinkSvg() {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.84294 6.15402C10.3032 6.37378 10.7041 6.7008 11.0118 7.10761C11.3195 7.51441 11.525 7.98911 11.6112 8.49184C11.6974 8.99457 11.6617 9.51065 11.5071 9.99671C11.3525 10.4828 11.0835 10.9246 10.7227 11.2852L7.53519 14.4727C6.93741 15.0705 6.12665 15.4063 5.28127 15.4063C4.43589 15.4063 3.62513 15.0705 3.02735 14.4727C2.42958 13.8749 2.09375 13.0642 2.09375 12.2188C2.09375 11.3734 2.42958 10.5626 3.02735 9.96485L4.27189 8.72031M13.7281 8.27973L14.9727 7.03519C15.5705 6.43741 15.9063 5.62665 15.9063 4.78127C15.9063 3.93589 15.5705 3.12513 14.9727 2.52735C14.3749 1.92958 13.5642 1.59375 12.7188 1.59375C11.8734 1.59375 11.0626 1.92958 10.4649 2.52735L7.27735 5.71485C6.91655 6.0754 6.64755 6.51726 6.49293 7.00333C6.33832 7.48939 6.30261 8.00547 6.3888 8.5082C6.47499 9.01093 6.68058 9.48563 6.98828 9.89243C7.29598 10.2992 7.69681 10.6263 8.1571 10.846"
        stroke="#A5A5A5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function LinkIcon() {
  return <Icon component={LinkSvg} />;
}

function MarkSvg() {
  return (
    <svg
      width={18}
      height={17}
      viewBox="0 0 18 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.125 7.4375C11.125 8.00108 10.9011 8.54159 10.5026 8.9401C10.1041 9.33862 9.56358 9.5625 9 9.5625C8.43641 9.5625 7.89591 9.33862 7.4974 8.9401C7.09888 8.54159 6.875 8.00108 6.875 7.4375C6.875 6.87391 7.09888 6.33341 7.4974 5.9349C7.89591 5.53638 8.43641 5.3125 9 5.3125C9.56358 5.3125 10.1041 5.53638 10.5026 5.9349C10.9011 6.33341 11.125 6.87391 11.125 7.4375Z"
        stroke="#A5A5A5"
        strokeWidth={1.2}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.3125 7.4375C14.3125 12.4964 9 15.4062 9 15.4062C9 15.4062 3.6875 12.4964 3.6875 7.4375C3.6875 6.02854 4.24721 4.67728 5.2435 3.681C6.23978 2.68471 7.59104 2.125 9 2.125C10.409 2.125 11.7602 2.68471 12.7565 3.681C13.7528 4.67728 14.3125 6.02854 14.3125 7.4375Z"
        stroke="#A5A5A5"
        strokeWidth={1.5}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
}
function MarkIcon() {
  return <Icon component={MarkSvg} />;
}

function RentSuggestions() {
  const isDark = useUnit($isDarkTheme);

  const data = [
    {
      type: 'Квартира',
      floor: 1,
      square: '-',
      total_cost: 6521,
      cost_meter: 5540,
    },
  ];

  const columns = [
    {
      title: 'Тип помещения',
      dataIndex: 'type',
      key: 'type',
    },
    {
      title: 'Этаж',
      dataIndex: 'floor',
      key: 'floor',
    },
    {
      title: 'Площадь',
      dataIndex: 'square',
      key: 'square',
    },
    {
      title: 'Стоимость общая',
      dataIndex: 'total_cost',
      key: 'total_cost',
    },
    {
      title: 'Стоимость за м2',
      dataIndex: 'cost_meter',
      key: 'cost_meter',
    },
    {
      title: 'Действия',
      key: 'actions',
      render: () => {
        return (
          <Space>
            <LinkIcon />
            <MarkIcon />
          </Space>
        );
      },
    },
  ];

  return (
    <div
      className={style.rent_suggestions}
      style={{ background: isDark ? '#1e2328' : 'white' }}
    >
      <Collapse
        expandIconPosition="end"
        ghost
        items={[
          {
            label: 'Актуальные предложения об аренде',
            children: (
              <Table
                dataSource={data}
                columns={columns}
                size="small"
                pagination={false}
              />
            ),
          },
        ]}
      />
    </div>
  );
}

export default RentSuggestions;
