import { Outlet } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { ConfigProvider } from 'antd';
import Header from './sections/Header.jsx';
import Footer from './sections/Footer.jsx';
import HeaderSmallMenu from './sections/HeaderSmallMenu.jsx';
import { lightTheme } from '../../themeConfigs/themeConfigs.js';

function RbpLanding() {
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const onScroll = () => setOffset(window.scrollY);
    window.removeEventListener('scroll', onScroll);
    window.addEventListener('scroll', onScroll, { passive: true });
    return () => window.removeEventListener('scroll', onScroll);
  }, []);

  return (
    <ConfigProvider theme={lightTheme}>
      <div style={{ background: '#FFFFFF', position: 'relative' }}>
        <Header offset={offset} />
        <HeaderSmallMenu />
        <Outlet />
        <Footer />
      </div>
    </ConfigProvider>
  );
}

export default RbpLanding;
