export function generateDetailByMetric(data, metrics) {
  return metrics.map((item) => {
    switch (item) {
      case 'rent_m2':
        return {
          type: 'table',
          metric: 'rent_m2',
          title: 'Средняя арендная плата в месяц за м2, тенге',
          data: [
            {
              apartment_type: 'Студии',
              no_floor: 5390,
              minus_floor: null,
              first_floor: 6521,
              second_floor: 5540,
            },
          ],
          columns: [
            {
              title: 'Тип помещения',
              dataIndex: 'apartment_type',
              key: 'apartment_type',
            },
            {
              title: 'Без учета этажа',
              dataIndex: 'no_floor',
              key: 'no_floor',
            },
            {
              title: '-1 этаж',
              dataIndex: 'minus_floor',
              key: 'minus_floor',
            },
            {
              title: '1 этаж',
              dataIndex: 'first_floor',
              key: 'first_floor',
            },
            {
              title: '2 этаж',
              dataIndex: 'second_floor',
              key: 'second_floor',
            },
          ],
          subTable: {
            data: [
              {
                common_use: 'Аренда зданий',
                no_floor: 7298,
                minus_floor: null,
                first_floor: 6521,
                second_floor: 5540,
              },
            ],
            columns: [
              {
                title: 'Общее назначение',
                dataIndex: 'common_use',
                key: 'common_use',
              },
              {
                // title: 'Без учета этажа',
                dataIndex: 'no_floor',
                key: 'no_floor',
              },
              {
                // title: '-1 этаж',
                dataIndex: 'minus_floor',
                key: 'minus_floor',
              },
              {
                // title: '1 этаж',
                dataIndex: 'first_floor',
                key: 'first_floor',
              },
              {
                // title: '2 этаж',
                dataIndex: 'second_floor',
                key: 'second_floor',
              },
            ],
          },
        };
      case 'population_total':
        return {
          title: 'Структура проживающего населения, чел.',
          type: 'columns',
          metric: 'population_total',
          data: {
            '18-29': {
              male: 100,
              female: 100,
            },
            '30-44': {
              male: 200,
              female: 150,
            },
            '45-60': {
              male: 300,
              female: 50,
            },
            '61+': {
              male: 200,
              female: 50,
            },
          },
        };
      case 'population_gender':
        return {
          title: 'Структура населения по полу',
          metric: 'population_gender',
          type: 'pie',
          data: {
            male: 78,
            female: 22,
          },
        };
      case 'working_total':
        return {
          type: 'columns',
          metric: 'working_total',
          title: 'Структура работающего населения, чел.',
          data: {
            '18-29': {
              male: 100,
              female: 100,
            },
            '30-44': {
              male: 200,
              female: 150,
            },
            '45-60': {
              male: 300,
              female: 50,
            },
            '61+': {
              male: 200,
              female: 50,
            },
          },
        };
      case 'households':
        return {
          title: 'Домохозяйства',
          metric: 'households',
          type: 'pie',
          data: {
            apartment: 78,
            private: 22,
          },
        };
      case 'income_total':
        return {
          title: 'Кол-во проживающего населения по уровням дохода, чел.',
          metric: 'income_total',
          type: 'columns',
          data: {
            '<100к ₸': {
              male: 100,
              female: 100,
            },
            '100-250к ₸': {
              male: 200,
              female: 150,
            },
            '250-500к ₸': {
              male: 300,
              female: 50,
            },
            '> 500к ₸': {
              male: 200,
              female: 50,
            },
          },
        };
      case 'competitors_cnt_B':
        return {
          type: 'table',
          metric: 'competitors_cnt_B',
          title: 'Количество конкурентов',
          data: [
            {
              business_type: 'Оптика',
              total_cnt: 35,
              city_avg: 8,
              types_cnt: 1,
            },
          ],
          columns: [
            {
              title: 'Тип бизнеса',
              dataIndex: 'business_type',
              key: 'business_type',
            },
            {
              title: 'Общее число ед',
              dataIndex: 'total_cnt',
              key: 'total_cnt',
            },
            {
              title: 'Среднее значение по городу, ед.',
              dataIndex: 'city_avg',
              key: 'city_avg',
            },
            {
              title: 'Кол-во типов, ед.',
              dataIndex: 'types_cnt',
              key: 'types_cnt',
            },
          ],
          subTable: {
            data: [
              {
                business_type: '-',
                total_cnt: '-',
                city_avg: '-',
              },
            ],
            columns: [
              {
                title: 'Подтип бизнеса',
                dataIndex: 'business_subtype',
                key: 'business_subtype',
              },
              {
                title: 'Общее число ед',
                dataIndex: 'total_cnt',
                key: 'total_cnt',
              },
              {
                title: 'Среднее значение по городу, ед.',
                dataIndex: 'city_avg',
                key: 'city_avg',
              },
            ],
          },
        };
      case 'potential':
        return {
          type: 'table',
          title: 'Расчетные охваты для выбранной локации',
          metric: 'potential',
          data: [
            {
              population: 'Проживающее',
              city_avg: 19103,
            },
          ],
          columns: [
            {
              title: 'Охват населения, чел.',
              dataIndex: 'population',
              key: 'population',
            },
            {
              title: 'Среднее значение (по городу), чел.',
              dataIndex: 'city_avg',
              key: 'city_avg',
            },
          ],
        };
      case 'spendings_total':
        return {
          title: 'Сумма трат в локации',
          metric: 'spendings_total',
          type: 'bar',
          data: {
            weekdays_day: {
              sum: 100,
            },
            weekdays_evening: {
              sum: 200,
            },
            weekends_day: {
              sum: 300,
            },
            weekends_evening: {
              sum: 200,
            },
          },
        };
      case 'traffic_total':
        return {
          title: 'Проходимость, чел в час',
          metric: 'traffic_total',
          type: 'bar',
          data: {
            weekdays_day: {
              sum: 100,
            },
            weekdays_evening: {
              sum: 200,
            },
            weekends_day: {
              sum: 300,
            },
            weekends_evening: {
              sum: 200,
            },
          },
        };
    }
  });
}

export const formatDetailsData = (hexagons, chosen_metric) => {
  switch (chosen_metric) {
    case 'rent':
      return generateDetailByMetric(hexagons, ['rent_m2']);
    case 'population':
      return generateDetailByMetric(hexagons, [
        'population_total',
        'population_gender',
      ]);
    case 'working':
      return generateDetailByMetric(hexagons, ['working_total']);
    case 'households':
      return generateDetailByMetric(hexagons, ['households']);
    case 'income':
      return generateDetailByMetric(hexagons, ['income_total']);
    case 'competitors_cnt_B':
      return generateDetailByMetric(hexagons, ['competitors_cnt_B']);
    case 'potential':
      return generateDetailByMetric(hexagons, ['potential']);
    case 'spendings':
      return generateDetailByMetric(hexagons, ['spendings_total']);
    case 'traffic':
      return generateDetailByMetric(hexagons, ['traffic_total']);
  }
};
