import { createEvent } from 'effector';
import {
  $cdekChosenDots,
  $expressChosenDots,
  $janaPostChosenDots,
  $kimigoChosenDots,
  $ozonChosenDots,
  $wildberriesChosenDots,
} from './stores.js';

export const changeTableChosenEv = createEvent();

export const changeTableOurChosenEv = createEvent();

export const changeCheckedPlacesEv = createEvent();

export const updateTableAndChartEv = createEvent();

export const selectAllPlacesEv = createEvent();

export const clearPlacesEv = createEvent();

export const invertPlacesEv = createEvent();

export const changeLayerTableEv = createEvent();

export const changeActiveLayersEv = createEvent();

export const changeExpressChosenDotsEv = createEvent();
export const changeCdekChosenDotsEv = createEvent();
export const changeJanaPostChosenDotsEv = createEvent();
export const changeKimigoChosenDotsEv = createEvent();
export const changeOzonChosenDotsEv = createEvent();
export const changeWildberriesChosenDotsEv = createEvent();

export const resetByGradientEv = createEvent()

export const setByPLaceEv = createEvent()
export const setWithGradientEv = createEvent()

export const toggleShowPopulationHexEv = createEvent()
